import {
  AlignLeft2Line,
  BarchartFilled,
  CalendarDatesFilled,
  Comment2QuestionFilled,
  Dashboard2Filled,
  DiscordBrand,
  DollarLine,
  EmailFilled,
  ExternalLinkLine,
  FileUploadFilled,
  GearFilled,
  Link1Line,
  Link2Filled,
  QuestionLine,
  SortNumberLine,
  TelegramBrand,
  TicketFilled,
  TiktokBrand,
  TwitterFilled,
  UserArrowRightFilled,
  WalletConnectBrand,
} from '@zealy/icons';

export const TASK_ICONS = {
  token: DollarLine,

  nft: TicketFilled,

  onChain: Link2Filled,

  partnership: UserArrowRightFilled,

  file: FileUploadFilled,

  image: FileUploadFilled,

  discord: DiscordBrand,

  telegram: TelegramBrand,

  twitter: TwitterFilled,

  tweet: TwitterFilled,

  tweetReact: TwitterFilled,

  twitterSpace: TwitterFilled,

  twitterFollow: TwitterFilled,

  poll: BarchartFilled,

  quiz: Comment2QuestionFilled,

  opinion: Dashboard2Filled,

  text: AlignLeft2Line,

  date: CalendarDatesFilled,

  number: SortNumberLine,

  url: Link1Line,

  visitLink: ExternalLinkLine,

  invites: EmailFilled,

  api: GearFilled,

  default: QuestionLine,

  wallet: WalletConnectBrand,

  tiktok: TiktokBrand,
} as const;

export type QuestTypeMappedKey = keyof typeof TASK_ICONS;
