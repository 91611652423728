import { apiV2 } from '@zealy/queries';

import type { APITask, TestResult } from './APITests.const';
import type { TestUserData } from './ConfigureTestUser';
import { TEN_SECONDS } from './APITests.const';
import { testUserSchema } from './ConfigureTestUser';

export const API_TEST_BODY = {
  requestId: '13663f80-0358-49ed-8d5e-301a5fce0f77',
  userId: '23663f80-0358-49ed-8d5e-301a5fce0f77',
  communityId: '33663f80-0358-49ed-8d5e-301a5fce0f77',
  questId: '43663f80-0358-49ed-8d5e-301a5fce0f77',
  subdomain: 'test',
  accounts: {
    discord: {
      handle: 'testhandle#1234',
      id: '123456789',
    },
    twitter: {
      username: 'testuser',
      id: '123456789',
    },
    wallet: '0x0000000000000000000000000000000000000000',
    email: 'example@example.com',
    'eth-mainnet': '0x0000000000000000000000000000000000000000',
    'zealy-connect': '123',
  },
} as const;

const getTestAccounts = (
  identifications: APITask['settings']['identifications'],
  testData?: TestUserData,
) =>
  identifications.reduce(
    (acc, accountType) => ({
      ...acc,
      [accountType]:
        (accountType === 'wallet' ? testData?.['eth-mainnet'] : testData?.[accountType]) ??
        API_TEST_BODY.accounts[accountType],
    }),
    {},
  );

export const runTests = async (
  { endpoint, apiKey, identifications }: APITask['settings'],
  community: {
    subdomain: string;
    id: string;
  },
  questId?: string,
  userId?: string,
): Promise<TestResult> => {
  const sendDate = new Date().getTime();

  const testDataString = localStorage.getItem('testUser');
  const testData = testDataString
    ? testUserSchema.safeParse(JSON.parse(testDataString))
    : undefined;

  const res = await apiV2.quest.testApiQuestEndpoint({
    params: { subdomain: community.subdomain },
    body: {
      ...API_TEST_BODY,
      apiKey,
      userId: userId ?? API_TEST_BODY.userId,
      questId: questId ?? API_TEST_BODY.questId,
      ...(community ? { subdomain: community.subdomain, communityId: community.id } : {}),
      accounts: getTestAccounts(identifications, testData?.success ? testData.data : undefined),
      endpoint,
    },
  });

  if (!res?.body || res.status !== 200) {
    return {
      success: false,
      reason: 'unknown-error',
      data: {
        data: 'Unknown error, please contact support',
      },
    };
  }

  const { status, data: responseData } = res.body;

  const data = { status, data: responseData as any };

  const responseTime = new Date().getTime() - sendDate;

  if (responseTime > TEN_SECONDS) {
    return { success: false, reason: 'response-time' };
  }

  if (status === 401 || status === 403) {
    return { success: false, reason: 'auth-error', data };
  }

  if (!data.data.message && status === 400) {
    return { success: false, reason: 'response-message', data };
  }

  if (![200, 400].includes(status)) {
    return { success: false, reason: 'response-status', data };
  }

  return { success: true, data };
};
