import { useController } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import { Avatar, TaskCard } from '@zealy/design-system';
import { EditLine } from '@zealy/icons';
import { useAuthenticatedUser } from '@zealy/queries';

import { TASK_CONFIG } from '#constants/quests/Task.constants';
import { ConnectWallet } from '#features/Wallets/ConnectWallet';

import type { QuestTask } from '../Tasks.types';
import { withQuest } from '../Tasks.funcs';

type WrapperProps = Extract<QuestTask, { type: 'token' }>;

const floatToString = (value: number) => {
  return value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 18,
  });
};

export const Token = ({ task, error }: { task: WrapperProps; error?: string }) => {
  const t = useTranslations('quest.type.token');
  const tSettings = useTranslations('settings.linked-accounts');

  const user = useAuthenticatedUser();

  return (
    <TaskCard type="token" title={t('label')} icon={TASK_CONFIG.token.icon}>
      <div className="p-300 flex flex-col gap-100">
        <div className="flex gap-100 items-center">
          {task.metadata?.logo && (
            <Avatar size="sm" src={task.metadata?.logo} name={task.metadata?.name} />
          )}

          <p className="body-paragraph-xl-bold text-primary">
            {t('title', {
              minBalance: floatToString(task.settings.minBalance),
              name: task.metadata?.name,
              symbol: task.metadata?.symbol,
              network: t(`fields.network.options.${task.settings.network}`),
            })}
          </p>
        </div>

        {user.data?.addresses?.[task.settings.network] && (
          <div className="flex items-center">
            <p className="body-component-sm text-secondary">
              {t('verification', {
                address: user.data?.addresses?.[task.settings.network],
              })}
            </p>
            <ConnectWallet
              blockchain={task.settings.network}
              buttonProps={{
                'aria-label': tSettings('update'),
                size: 'xs',
                color: 'default',
                mutedText: true,
                leftIcon: <EditLine />,
                onlyIcon: true,
                variant: 'ghost',
              }}
            />
          </div>
        )}

        {error && <p className="body-component-md text-error-primary">{error}</p>}
      </div>
    </TaskCard>
  );
};

const TokenClaim = withQuest<WrapperProps, 'token'>(({ task }) => {
  const { formState } = useController({
    name: task.id,
    defaultValue: {
      taskId: task.id,
      type: task.type,
    },
  });

  const error = formState.errors?.[task.id]?.message;

  return <Token task={task} error={error ? String(error) : undefined} />;
});

export default TokenClaim;
