import { useWatch } from 'react-hook-form';

import { TagAnatomy as Anatomy } from '@zealy/design-system';
import { StarFilled } from '@zealy/icons';
import { TaskOutput } from '@zealy/utils';

import { PreviewTitle } from './PreviewTitle';

export const OpinionPreview = ({ index }: { index: number }) => {
  const settings: Extract<TaskOutput, { type: 'opinion' }>['settings'] = useWatch({
    name: `tasks.${index}.settings`,
  });

  const { start, end } =
    settings?.scaleType === 'numerical'
      ? settings.step
      : { start: 1, end: Number(settings.starCount) || 5 };

  const steps = Array.from(
    {
      length: Math.min(Number(end), 100) - Math.max(Number(start), -20) + 1,
    },
    (_, idx) => idx + Number(start),
  );

  return (
    <PreviewTitle index={index}>
      <div>
        <div className="flex gap-100 min-w-0 flex-wrap">
          {steps?.map(step =>
            settings?.scaleType === 'numerical' ? (
              <Anatomy.Root key={step} className="!px-0 min-w-tag-lg 2xl:flex-1" size="lg">
                <Anatomy.Label className="!text-center">{step}</Anatomy.Label>
              </Anatomy.Root>
            ) : (
              <StarFilled key={step} size={40} className="icon-disabled-solid" />
            ),
          )}
        </div>
        {settings?.scaleType === 'numerical' && settings.label && (
          <div className="flex justify-between mt-100">
            <p className="body-paragraph-md text-primary">{settings.label.start}</p>
            <p className="body-paragraph-md text-primary">{settings.label.end}</p>
          </div>
        )}
      </div>
    </PreviewTitle>
  );
};
