import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslations } from 'next-intl';
import { DiscordBrand } from '@zealy/icons';
import { cn } from '../../utils/cn';
import { Button } from '../Button';
import { formControlStyles } from '../FormControl/FormControl.styles';
import { TaskCard } from '../TaskCard';
import { socialActionStyles, socialInteractionContainerStyles } from './SocialTaskCards.styles';
export function DiscordJoinServerCard({ className, metadata, inviteLink, error, ...props }) {
    const t = useTranslations();
    return (_jsx("div", { className: cn(socialInteractionContainerStyles(), className), ...props, children: _jsx(TaskCard, { type: "discord", title: t('ds.social.discord.join.header'), children: _jsxs("div", { className: "flex flex-col gap-300 px-200 py-400", children: [_jsxs("div", { className: socialActionStyles.container(), children: [_jsxs("div", { className: "flex flex-col items-center gap-100", children: [metadata?.imageUrl && (_jsx("div", { className: socialActionStyles.avatar.wrapper(), children: _jsx("img", { className: socialActionStyles.avatar.image(), src: metadata?.imageUrl, alt: metadata?.name }) })), _jsxs("div", { className: "flex flex-col items-center", children: [_jsx("a", { href: inviteLink, className: socialActionStyles.name(), children: _jsx("span", { children: metadata?.name }) }), metadata?.description && (_jsx("span", { className: socialActionStyles.description(), children: metadata?.description }))] })] }), _jsx("div", { className: "flex flex-col gap-100 w-full", children: _jsx(Button, { leftIcon: _jsx(DiscordBrand, {}), className: "text-white w-full bg-task-type-discord hover:bg-task-type-discord-hover active:bg-task-type-discord-press", color: "cta", variant: "filled", size: "lg", as: "a", href: inviteLink, target: "_blank", rel: "noopener noreferrer", children: _jsx("span", { className: "text-ellipsis line-clamp-1 justify-start", children: metadata?.imageUrl && metadata?.description
                                            ? t('ds.social.discord.join.join')
                                            : t.rich('ds.social.discord.join.join-server', {
                                                serverName: metadata?.name,
                                                s: chunk => _jsxs(_Fragment, { children: ["'", chunk, "'"] }),
                                            }) }) }) })] }), error && _jsx("p", { className: formControlStyles.hint({ state: 'error' }), children: error })] }) }) }));
}
