import type { QuestType } from '../Tasks.types';
import Api from './Api';
import Discord from './Discord';
import FileUpload from './FileUpload';
import Invites from './Invites';
import NFT from './NFT';
import OnChain from './OnChain';
import Opinion from './Opinion';
import Partnership from './Partnership';
import Poll from './Poll';
import Quiz from './Quiz';
import Telegram from './Telegram';
import TextSubmission from './TextSubmission';
import TikTok from './TikTok';
import Token from './Token';
import { TweetClaim } from './TweetClaim';
import { TweetReactClaim } from './TweetReact';
import { TwitterFollowClaim } from './TwitterFollow';
import { TwitterSpaceClaim } from './TwitterSpace';
import VisitLink from './VisitLink';

// for now it doesn't make sense to add a type here
export const taskClaimMap = new Map<QuestType, any>();
taskClaimMap.set('api', Api);
taskClaimMap.set('nft', NFT);
taskClaimMap.set('token', Token);
taskClaimMap.set('onChain', OnChain);
taskClaimMap.set('partnership', Partnership);
taskClaimMap.set('text', TextSubmission);
taskClaimMap.set('poll', Poll);
taskClaimMap.set('quiz', Quiz);
taskClaimMap.set('invites', Invites);
taskClaimMap.set('visitLink', VisitLink);
taskClaimMap.set('telegram', Telegram);
taskClaimMap.set('discord', Discord);
taskClaimMap.set('tweet', TweetClaim);
taskClaimMap.set('tweetReact', TweetReactClaim);
taskClaimMap.set('twitterFollow', TwitterFollowClaim);
taskClaimMap.set('twitterSpace', TwitterSpaceClaim);
taskClaimMap.set('file', FileUpload);
taskClaimMap.set('number', TextSubmission);
taskClaimMap.set('url', TextSubmission);
taskClaimMap.set('opinion', Opinion);
taskClaimMap.set('tiktok', TikTok);
