import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import { Switch } from '@zealy/design-system';

export const SwitchField = ({
  name,
  namespace,
  hideSwitch,
}: {
  name: string;
  namespace: string;
  hideSwitch?: boolean;
}) => {
  const t = useTranslations('quest.type');

  // @ts-ignore
  const label = t(`${namespace}.label`);
  // @ts-ignore
  const description = t(`${namespace}.description`);

  return (
    <div className="flex gap-300">
      <div className="flex flex-col gap-25 flex-1">
        <label htmlFor={name} className="input-label-md text-task-card-primary">
          {label}
        </label>
        <p className="input-hint-md text-task-card-secondary">{description}</p>
      </div>
      {!hideSwitch && (
        <Controller
          name={name}
          render={({ field: { value, onChange } }) => (
            <Switch id={name} checked={value} onCheckedChange={onChange} />
          )}
        />
      )}
    </div>
  );
};
