import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import { Select, SelectItem } from '@zealy/design-system';

import { useError } from '../../../../FormError.context';

// allows any object
export const access = (path: string, object: any): string | undefined => {
  return path.split('.').reduce((o, i) => o?.[i], object);
};

export const SelectField = ({
  name,
  namespace,
  options,
  ...props
}: {
  name: string;
  namespace: string;
  options: string[];
}) => {
  const t = useTranslations('quest.type');
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const context = useError(name);

  // @ts-ignore
  const label = t(`${namespace}.label`);
  // @ts-ignore
  const placeholder = t(`${namespace}.placeholder`);
  // @ts-ignore
  const hint = t(`${namespace}.hint`);

  const error = access(`${name}.message`, errors) ?? context.error;

  return (
    <Select
      name={name}
      label={label}
      placeholder={placeholder}
      {...props}
      isInvalid={!!error}
      hint={error ?? hint}
      value={watch(name)}
      onValueChange={value =>
        setValue(name, value, {
          shouldValidate: true,
          shouldDirty: true,
        })
      }
    >
      {options.map(option => (
        <SelectItem key={option} value={option}>
          {/* @ts-ignore */}
          {t(`${namespace}.options.${option}`)}
        </SelectItem>
      ))}
    </Select>
  );
};
