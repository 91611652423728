import type { ButtonProps } from '@zealy/design-system';
import type { BlockchainNetwork } from '@zealy/utils';

import { ConnectButton } from './EVM/ConnectButton';
import { ConnectSolana } from './Solana/ConnectSolana';
import { ConnectTon } from './Ton/ConnectTon';

export const ConnectWallet = ({
  blockchain,
  buttonProps,
}: {
  blockchain: BlockchainNetwork;
  buttonProps?: ButtonProps;
}) => {
  switch (blockchain) {
    case 'sol-mainnet':
      return <ConnectSolana buttonProps={buttonProps} />;
    case 'ton-mainnet':
      return <ConnectTon buttonProps={buttonProps} />;

    default:
      return <ConnectButton blockchain={blockchain} buttonProps={buttonProps} />;
  }
};
