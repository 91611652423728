import { cx } from 'class-variance-authority';
const root = cx([
    // Base
    'outline-none group/switch',
    // Shared tokens
    'h-control-container-xs-y w-control-container-xs-x rounded-control-switch',
    // Unchecked
    'data-[state=unchecked]:bg-control-switch-unselected',
    // Checked
    'data-[state=checked]:bg-control-switch-selected',
    // Disabled
    'disabled:bg-control-switch-disabled disabled:cursor-not-allowed',
    // Interactions
    'transition-colors duration-150 ease-out',
]);
const thumb = cx([
    // Base
    'flex justify-center items-center relative',
    // Animation
    'transition',
    'translate-x-[2px] data-[state=checked]:translate-x-[18px]',
    // Shared tokens
    'h-control-handle-xs w-control-handle-xs rounded-control-switch shadow-control-switch-xs bg-control-container',
    // Disabled
    'group-disabled/switch:bg-control-switch-disabled',
]);
const thumbInner = cx([
    // Base
    'block',
    // Shared tokens
    'min-h-control-handle-xs min-w-control-handle-xs rounded-control-switch',
    // Animation
    'transition-all duration-150',
    // Hover
    'group-hover/switch:min-h-[36px] group-hover/switch:min-w-[36px]',
    'group-hover/switch:bg-control-state-layer-hover',
    // Active
    'group-active/switch:min-h-[36px] group-active/switch:min-w-[36px]',
    'group-active/switch:bg-control-state-layer-press',
    // Disabled
    'group-disabled/switch:bg-transparent',
    // Focus
    'group-focus-visible/switch:min-h-[36px] group-focus-visible/switch:min-w-[36px]',
    'group-focus-visible/switch:ring-control-focus ring-inset group-focus-visible/switch:ring-control-state-layer-focus',
]);
const label = cx('text-control-primary control-md aria-disabled:text-control-disabled');
export const styles = {
    root,
    thumb,
    thumbInner,
    label,
};
