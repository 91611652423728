import { useController, useFormContext } from 'react-hook-form';
import { useCookie } from 'react-use';

import type { EmbedURLProps } from '@zealy/design-system';
import { EmbedURL, isEmbeddable } from '@zealy/design-system';
import { useOpenGraph } from '@zealy/queries';

import { useError } from '#app/cw/[subdomain]/(app)/questboard/admin/[questId]/_components/FormError.context';

import type { ClaimTaskInput } from '../Tasks.types';
import { withQuest } from '../Tasks.funcs';

const type = 'visitLink' as const;
type Task = typeof type;

const VisitLink = withQuest<EmbedURLProps, Task>(({ task }) => {
  const [visited, setVisited] = useCookie(task.id);
  const { control } = useFormContext<Record<string, ClaimTaskInput<Task>>>();

  const { data, isLoading } = useOpenGraph(task.settings.linkUrl);

  useController({
    name: task.id,
    control,
    defaultValue: {
      taskId: task.id,
      type,
    },
  });

  const interactError = useError(
    task.id,
    isEmbeddable(task.settings.linkUrl)
      ? undefined
      : visited === task.settings.linkUrl
      ? undefined
      : 'Interaction required',
  );

  const onInteract = () => {
    interactError.clearError();
    setVisited(task.settings.linkUrl, { expires: 1 });
  };

  return (
    <EmbedURL
      {...data}
      loading={isLoading}
      url={task.settings.linkUrl}
      asTask
      target="_blank"
      rel="noopener noreferrer"
      onClick={onInteract}
    />
  );
});

export default VisitLink;
