'use client';

import './solana.css';

import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import React, { useEffect } from 'react';
import base58 from 'bs58';
import { useTranslations } from 'next-intl';

import type { ButtonProps } from '@zealy/design-system';
import { useAuthenticatedUser } from '@zealy/queries';

import { toast } from '#components/Toaster';

import { authenticationAdapter } from './authenticationAdapter';
import { SolanaProvider } from './SolanaProvider';

const SolanaAuthenticationProvider = ({ children }: { children: React.ReactNode }) => {
  const { refetch } = useAuthenticatedUser();
  const { publicKey, signMessage, disconnect, connected } = useWallet();

  const methods = authenticationAdapter(refetch);

  const signCustomMessage = async () => {
    if (!publicKey) {
      throw new Error('Wallet not available to process request.');
    }
    const address = publicKey.toBase58();

    console.log('[Solana]: Fetching nonce...');

    const nonce = await methods.getNonce();

    console.log('[Solana]: Preparing message to sign');

    const message = methods.createMessage({ nonce, address });
    const body = methods.getMessageBody({ message });
    const encodedMessage = new TextEncoder().encode(body);

    if (!encodedMessage) {
      throw new Error('Failed to get encoded message.');
    }

    try {
      const signedMessage = await signMessage?.(encodedMessage);
      const signature = base58.encode(signedMessage as Uint8Array);
      console.log('[Solana]: Verifying signature');

      await methods.verify({ message, signature });
    } catch (e) {
      await disconnect();
      throw e;
    }
  };

  useEffect(() => {
    if (connected) {
      signCustomMessage().catch(e => {
        if (e instanceof Error) {
          console.log(e);
          toast.error(e.message);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected]);

  return <>{children}</>;
};

const ConnectButton = ({ buttonProps }: { buttonProps?: ButtonProps }) => {
  const t = useTranslations('settings.linked-accounts');
  const { connected } = useWallet();

  const getLabel = () => {
    if (buttonProps && buttonProps['aria-label']) {
      return buttonProps['aria-label'];
    }
    if (connected) {
      return t('sign-message');
    }
    return t('connect-wallet');
  };

  return <WalletMultiButton {...buttonProps}>{getLabel()}</WalletMultiButton>;
};

// https://docs.moralis.io/authentication-api/solana/how-to-sign-in-with-solana-wallet-provider
export const ConnectSolana = ({ buttonProps }: { buttonProps?: ButtonProps }) => {
  return (
    <SolanaProvider>
      <SolanaAuthenticationProvider>
        <ConnectButton buttonProps={buttonProps} />
      </SolanaAuthenticationProvider>
    </SolanaProvider>
  );
};
