import { useController, useFormContext } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import { TagAnatomy as Anatomy, formControlStyles, TaskCard } from '@zealy/design-system';
import { StarFilled } from '@zealy/icons';

import { TASK_CONFIG } from '#constants/quests/Task.constants';

import type { QuestTask } from '../Tasks.types';
import { withQuest } from '../Tasks.funcs';

type WrapperProps = Extract<QuestTask, { type: 'opinion' }>;

const type = 'opinion' as const;
type Task = typeof type;

const Opinion = withQuest<WrapperProps, Task>(({ task }) => {
  const t = useTranslations('quest.type.opinion');
  const { control, formState } = useFormContext();

  const { field } = useController({
    name: task.id,
    control,
    defaultValue: {
      taskId: task.id,
      value: undefined,
      type,
    },
  });

  const { start, end } =
    task.settings?.scaleType === 'numerical'
      ? task.settings.step
      : { start: 1, end: Number(task.settings.starCount) || 5 };

  const steps = Array.from(
    {
      length: Number(end) - Number(start) + 1,
    },
    (_, idx) => idx + Number(start),
  );

  const onChange = (value: number) => {
    field.onChange({ ...field.value, value });
  };

  return (
    <TaskCard type="opinion" title={t('label')} icon={TASK_CONFIG.opinion.icon}>
      <div className="p-300 flex flex-col gap-100">
        {task.settings.title && (
          <p className="body-paragraph-xl-bold text-primary">{task.settings.title}</p>
        )}
        {task.settings.description && (
          <p className="body-paragraph-lg text-secondary">{task.settings.description}</p>
        )}

        <div className="mt-200">
          <div className="flex gap-100 min-w-0 flex-wrap">
            {steps?.map(step =>
              task.settings?.scaleType === 'numerical' ? (
                <Anatomy.Root
                  key={step}
                  className="!px-0 min-w-tag-lg 2xl:flex-1"
                  size="lg"
                  onClick={() => onChange(step)}
                  selected={Number(field.value.value) == step}
                >
                  <Anatomy.Label className="!text-center">{step}</Anatomy.Label>
                </Anatomy.Root>
              ) : (
                <StarFilled
                  key={step}
                  size={40}
                  className="icon-disabled-solid hover:icon-secondary cursor-pointer data-[selected=true]:icon-primary"
                  onClick={() => onChange(step)}
                  data-selected={step <= Number(field.value.value)}
                />
              ),
            )}
          </div>
          {task.settings?.scaleType === 'numerical' && task.settings.label && (
            <div className="flex justify-between mt-100">
              <p className="body-paragraph-md text-primary">{task.settings.label.start}</p>
              <p className="body-paragraph-md text-primary">{task.settings.label.end}</p>
            </div>
          )}
        </div>
      </div>
      {formState.errors?.[task.id]?.message && (
        <p className={formControlStyles.hint({ state: 'error' })}>
          {String(formState.errors?.[task.id]?.message)}
        </p>
      )}
    </TaskCard>
  );
});

export default Opinion;
