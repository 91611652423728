'use client';

import { getCommunityPath } from '@zealy/utils';

import { useCommunity } from '@zealy/queries';

export const useCommunityURL = () => {
  const { data } = useCommunity();

  return data ? getCommunityPath(data) : '';
};
