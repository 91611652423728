import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslations } from 'next-intl';
import { TelegramBrand } from '@zealy/icons';
import { Button } from '../Button';
import { TaskCard } from '../TaskCard';
import { socialActionStyles } from './SocialTaskCards.styles';
export function TelegramJoinGroupCard({ metadata, inviteLink, ...props }) {
    const t = useTranslations('ds.social.telegram.join');
    return (_jsx(TaskCard, { type: "telegram", title: t('header'), ...props, children: _jsx("div", { className: "flex flex-col gap-300 px-200 py-400 min-w-0", children: _jsxs("div", { className: socialActionStyles.container(), children: [(!!metadata?.imageUrl || !!metadata?.description) && (_jsxs("div", { className: "flex flex-col items-center gap-100", children: [metadata.imageUrl && (_jsx("div", { className: socialActionStyles.avatar.wrapper(), children: _jsx("img", { className: socialActionStyles.avatar.image(), src: metadata?.imageUrl, alt: metadata?.name }) })), _jsxs("div", { className: "flex flex-col items-center", children: [_jsx("a", { href: inviteLink, className: socialActionStyles.name(), children: _jsx("span", { children: metadata?.name }) }), _jsx("span", { className: socialActionStyles.description(), children: metadata?.description })] })] })), _jsx(Button, { leftIcon: _jsx(TelegramBrand, {}), className: 'text-white w-full bg-task-type-telegram hover:bg-task-type-telegram-hover active:bg-task-type-telegram-press min-w-0', size: 'lg', as: "a", href: inviteLink, target: "_blank", rel: "noopener noreferrer", children: _jsx("span", { className: "text-ellipsis line-clamp-1 justify-start", children: metadata?.imageUrl && metadata?.description
                                ? t('join')
                                : t.rich('join-channel', {
                                    serverName: metadata?.name,
                                    s: chunk => (metadata?.name ? _jsxs(_Fragment, { children: ["'", chunk, "'"] }) : null),
                                }) }) })] }) }) }));
}
