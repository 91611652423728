import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import type { TagProps } from '@zealy/design-system';
import type { PollTask, QuizTask, TaskType } from '@zealy/utils';
import { TagAnatomy as Anatomy, Button, tagStyles as styles } from '@zealy/design-system';
import { CrossLine, PlusLine } from '@zealy/icons';

import { cn } from '#utils/utils';

import { PreviewTitle } from './PreviewTitle';

export const PollOption = ({
  namespace,
  tagType,
  index,
  onRemove,
  field,
  updateLabel,
  toggleSelected,
}: {
  namespace: string;
  index: number;
  tagType: TagProps['tagType'];
  onRemove: () => void;
  field: {
    correctAnswer: boolean;
    label: string;
  };
  toggleSelected: () => void;
  updateLabel: (label: string) => void;
}) => {
  const t = useTranslations('quest.type.poll');

  return (
    <div className="relative group/option">
      <Anatomy.Root
        size="lg"
        className="w-full"
        selected={field.correctAnswer}
        onClick={toggleSelected}
        as="span"
        data-state={field.correctAnswer ? 'on' : 'off'}
      >
        <Anatomy.LeftIcon tagType={tagType} />
        <label htmlFor={`${namespace}.${index}.label`} className="sr-only">
          {t('custom')}
        </label>
        <input
          className={cn(
            styles.input({ size: 'lg' }),
            'placeholder:text-primary placeholder:italic',
          )}
          id={`${namespace}.${index}.label`}
          placeholder={t('custom')}
          onClick={e => e.stopPropagation()}
          maxLength={300}
          name={`${namespace}.${index}.label`}
          onBlur={({ target: { value } }) => {
            if (value !== field.label) {
              updateLabel(value);
            }
          }}
          defaultValue={field.label}
          autoFocus={field.label === ''}
        />
      </Anatomy.Root>
      <Button
        onlyIcon
        leftIcon={<CrossLine />}
        variant="muted"
        size="xs"
        className="absolute top-0 bottom-0 my-auto -right-[10px] opacity-0 group-hover/option:opacity-100 hover:opacity-100 transition-opacity z-[1]"
        onClick={onRemove}
      />
    </div>
  );
};

// TODO: need to design how to set the image https://linear.app/zealy/issue/A-1486/add-images-to-quiz-options
export const PollPreview = ({ type, index }: { type: TaskType; index: number }) => {
  const t = useTranslations('quest.type.poll');
  const { watch, control } = useFormContext<{ tasks: (PollTask | QuizTask)[] }>();

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: `tasks.${index}.settings.options`,
  });

  const settings = watch(`tasks.${index}.settings`);

  const otherOption = type === 'poll' && (settings as PollTask['settings'])?.otherOption;

  const allowMultipleSelection = type === 'quiz' && settings?.multipleSelection;

  const tagType = settings?.multipleSelection ? 'checkbox' : 'radio';

  useEffect(() => {
    if (type === 'poll' && fields.length > 0) {
      fields.forEach(
        (
          {
            correctAnswer,
            ...field
          }: {
            id: string;
            label: string;
            correctAnswer?: boolean;
          },
          i,
        ) => {
          if (correctAnswer) update(i, field);
        },
      );
    }
    if (type === 'quiz' && fields.length > 0) {
      const correctAnswers = fields.filter((item: any) => item.correctAnswer);
      if (correctAnswers.length === 0) {
        update(0, {
          ...fields[0],
          // @ts-ignore
          correctAnswer: true,
        });
      } else if (!allowMultipleSelection && correctAnswers.length > 1) {
        for (let i = 1; i < fields.length; i++) {
          update(i, {
            ...fields[i],
            // @ts-ignore
            correctAnswer: fields[i].correctAnswer && correctAnswers[0].id === fields[i].id,
          });
        }
      }
    }
  }, [type, fields, update, allowMultipleSelection]);

  const toggleSelected = (optionIndex: number) => () => {
    if (type === 'poll') return;
    if (tagType === 'checkbox') {
      update(optionIndex, {
        ...fields[optionIndex],
        // @ts-ignore
        correctAnswer: !fields[optionIndex].correctAnswer,
      });
    } else {
      for (let i = 0; i < fields.length; i++) {
        update(i, {
          ...fields[i],
          // @ts-ignore
          correctAnswer: i === optionIndex,
        });
      }
    }
  };

  return (
    <PreviewTitle index={index}>
      <div className="grid @sm:grid-cols-2 gap-150">
        {fields.map((item, i) => (
          <PollOption
            key={item.id}
            namespace={`tasks.${index}.settings.options`}
            index={i}
            tagType={tagType}
            updateLabel={label => update(i, { ...fields[i], label })}
            onRemove={() => remove(i)}
            toggleSelected={toggleSelected(i)}
            field={item as any}
          />
        ))}
        {otherOption && (
          <Anatomy.Root size="lg" className="w-full" key="other">
            <Anatomy.LeftIcon tagType={tagType} />
            <input className={cn(styles.input({ size: 'lg' }))} placeholder={t('other')} readOnly />
          </Anatomy.Root>
        )}
        <Button
          leftIcon={<PlusLine />}
          variant="muted"
          mutedText
          size="lg"
          className="!justify-start"
          onClick={() =>
            append({
              label: '',
            })
          }
        >
          {t('add')}
        </Button>
      </div>
    </PreviewTitle>
  );
};
