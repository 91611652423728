import { useEffect } from 'react';
import { useController } from 'react-hook-form';
import { useTranslations } from 'next-intl';
import { usePathname } from 'next/navigation';

import { Avatar, Button, TaskCard } from '@zealy/design-system';
import { EditLine } from '@zealy/icons';
import { useAuthenticatedUser, useNFTCollectionMetadata } from '@zealy/queries';
import { NATIVE_TOKEN_ADDRESS } from '@zealy/utils';

import { useError } from '#app/cw/[subdomain]/(app)/questboard/admin/[questId]/_components/FormError.context';
import { TASK_CONFIG } from '#constants/quests/Task.constants';
import { ConnectWallet } from '#features/Wallets/ConnectWallet';

import type { QuestTask } from '../Tasks.types';
import { withQuest } from '../Tasks.funcs';

type WrapperProps = Extract<QuestTask, { type: 'nft' }>;

export const NFT = ({ task, error }: { task: WrapperProps; error?: string }) => {
  const t = useTranslations('quest.type.nft');
  const tSettings = useTranslations('settings.linked-accounts');
  const user = useAuthenticatedUser();

  const pathname = usePathname();
  const isEditor = pathname.includes('/questboard/admin/');

  const nftsContractMetadata = useNFTCollectionMetadata(
    {
      contract: task.settings.contract,
      network: task.settings.network,
    },
    {
      enabled:
        isEditor &&
        !!task.settings.contract &&
        !!task.settings.network &&
        task.settings.contract !== NATIVE_TOKEN_ADDRESS,
    },
  );

  const metadata = nftsContractMetadata.data
    ? nftsContractMetadata.data
    : !nftsContractMetadata.isError
    ? task.metadata
    : {};

  const metadataError = useError(task.id ?? task.settings.contract, undefined);

  useEffect(() => {
    if (nftsContractMetadata.isError) {
      metadataError.setError(nftsContractMetadata.error?.message);
    } else {
      metadataError.clearError();
    }
    return () => {
      metadataError.clearError();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nftsContractMetadata?.isError]);

  return (
    <TaskCard type="nft" title={t('label')} icon={TASK_CONFIG.nft.icon}>
      <div className="p-300 flex flex-col gap-100">
        {metadata?.image && <Avatar size="xl" src={metadata.image} name={metadata.name} />}
        <p className="body-paragraph-xl-bold text-primary">
          {t('title', {
            minBalance: task.settings.minBalance,
            name: metadata?.name,
          })}
        </p>

        {metadata?.description && (
          <p className="body-paragraph-lg text-secondary">{metadata.description}</p>
        )}

        {user.data?.addresses?.[task.settings.network] && (
          <div className="flex gap-50 items-center">
            <p className="body-component-sm text-secondary">
              {t('verification', {
                address: user.data?.addresses?.[task.settings.network],
              })}
            </p>
            <ConnectWallet
              blockchain={task.settings.network}
              buttonProps={{
                'aria-label': tSettings('update'),
                size: 'xs',
                color: 'default',
                mutedText: true,
                leftIcon: <EditLine />,
                onlyIcon: true,
                variant: 'ghost',
              }}
            />
          </div>
        )}

        {metadata?.link && (
          <Button
            size="lg"
            as="a"
            href={metadata.link}
            target="_blank"
            rel="noopener noreferrer"
            variant="muted"
          >
            {t('link-label')}
          </Button>
        )}

        {(error ?? metadataError.error) && (
          <p className="body-component-md text-error-primary">{error ?? metadataError.error}</p>
        )}
      </div>
    </TaskCard>
  );
};

const NFTClaim = withQuest<WrapperProps, 'nft'>(({ task }) => {
  const { formState } = useController({
    name: task.id,
    defaultValue: {
      taskId: task.id,
      type: task.type,
    },
  });

  const error = formState.errors?.[task.id]?.message;

  return <NFT task={task} error={error ? String(error) : undefined} />;
});

export default NFTClaim;
