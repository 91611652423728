import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslations } from 'next-intl';
import Link from 'next/link';

import { Button } from '@zealy/design-system';
import { ArrowRightLine } from '@zealy/icons';

import { useDebouncedValue } from '#hooks/useDebouncedValue';

import type { APITask } from './APITests.const';
import { useError } from '../../../../../FormError.context';
import { APITests } from './APITests';
import { runTests } from './APITests.utils';

export const API = ({ name }: { name: string }) => {
  const t = useTranslations('common');

  const { watch } = useFormContext();

  const { setError, clearError } = useError(name);

  const fields: APITask['settings'] = watch(name);

  const debouncedEndpoint = useDebouncedValue(fields.endpoint, 500);

  return (
    <>
      <APITests
        fields={{
          ...fields,
          endpoint: debouncedEndpoint,
        }}
        setError={(message?: string) => {
          if (!message) clearError();
          else setError(message);
        }}
        runTests={runTests}
      />

      <Button
        size="sm"
        variant="ghost"
        mutedText
        rightIcon={<ArrowRightLine />}
        as={Link}
        href="/docs/tasks/api"
        className="self-start"
      >
        {t('documentation')}
      </Button>
    </>
  );
};
