import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import type { TwitterFollowTask } from '@zealy/utils';
import { useTwitterAccount } from '@zealy/queries';

import { TwitterFollow } from '#components/Tasks/components/TwitterFollow';
import { useDebouncedValue } from '#hooks/useDebouncedValue';

import { useError } from '../../../../FormError.context';

export const TwitterFollowPreview = ({ index }: { index: number }) => {
  const t = useTranslations('quest.type.twitterFollow.errors');

  const { formState } = useFormContext<{ tasks: TwitterFollowTask[] }>();

  const task = useWatch({ name: `tasks.${index}` });

  const { setError, error, clearError } = useError(`tasks.${index}.settings.username`);

  const fieldIsTouched = formState.touchedFields?.['tasks']?.[index]?.['settings']?.['username'];
  const fieldIsValid =
    !formState.errors?.['tasks']?.[index]?.['settings']?.['username']?.['message'];

  const debouncedValue = useDebouncedValue(fieldIsValid ? task.settings.username : '', 500);

  const twitterAccount = useTwitterAccount(debouncedValue, {
    enabled: !!debouncedValue && (fieldIsTouched || !task?.metadata),
  });

  useEffect(() => {
    if (!twitterAccount.isFetched) return;

    if (twitterAccount.data?.id) clearError();
    else setError(t('not-found'));

    return clearError;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twitterAccount.isFetched, twitterAccount.data?.id]);

  return (
    <TwitterFollow
      task={{
        ...task,
        metadata: {
          ...twitterAccount.data,
          imageUrl: twitterAccount.data?.profile_image_url,
        },
      }}
      error={
        fieldIsTouched
          ? error ?? formState.errors?.['tasks']?.[index]?.['settings']?.['username']?.['message']
          : ''
      }
    />
  );
};
