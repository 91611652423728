import { generateTonPayload, verifyTonProof } from '@zealy/queries';
import { VerifyTonProofInput } from '@zealy/schemas';

interface AuthenticationAdapter {
  getTonPayload: () => Promise<string>;
  verifyTonProof: (proof: VerifyTonProofInput) => Promise<boolean>;
}

export const authenticationAdapter = (onSuccess?: () => void): AuthenticationAdapter => ({
  getTonPayload: generateTonPayload,
  verifyTonProof: async (payload: VerifyTonProofInput) => {
    try {
      await verifyTonProof(payload);
      onSuccess?.();
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
});
