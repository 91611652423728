import { cx } from 'class-variance-authority';
export const containerStyle = cx([
    'group/text-submission flex flex-col gap-300 px-200 py-400',
    'data-[is-disabled="true"]:bg-input-disabled',
]);
export const disabledStyle = cx(['group-data-[is-disabled="true"]/text-submission:text-disabled']);
export const titleStyle = cx([
    'text-oncomponent-secondary min-w-0 input-text-xl-bold',
    disabledStyle,
]);
export const descriptionStyle = cx(['text-secondary min-w-0 break-words', disabledStyle]);
export const infoStyle = cx(['flex flex-col gap-200']);
