import { useWatch } from 'react-hook-form';

import { TelegramJoinGroupCard } from '@zealy/design-system';
import { useTelegramMetadata } from '@zealy/queries';

import { useDebouncedValue } from '#hooks/useDebouncedValue';

export const TelegramPreview = ({ index }: { index: number }) => {
  const task = useWatch({
    name: `tasks.${index}`,
  });

  const debouncedValue = useDebouncedValue(task?.settings?.inviteUrl as string, 500);

  const { data } = useTelegramMetadata(debouncedValue);

  return (
    <TelegramJoinGroupCard
      inviteLink={task?.settings?.inviteUrl}
      metadata={data ?? task['metadata']}
    />
  );
};
