import '@rainbow-me/rainbowkit/styles.css';

import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import {
  arbitrum,
  avalanche,
  base,
  bsc,
  chiliz,
  cronos,
  fantom,
  gnosis,
  linea,
  mainnet,
  moonbeam,
  optimism,
  palm,
  polygon,
  polygonZkEvm,
  zkSync,
} from 'wagmi/chains';

import { envConfig } from '#app/config';

export const config = getDefaultConfig({
  appName: 'Zealy',
  projectId: envConfig.walletconnect.projectId,
  chains: [
    mainnet,
    polygon,
    optimism,
    arbitrum,
    base,
    bsc,
    linea,
    avalanche,
    fantom,
    cronos,
    palm,
    gnosis,
    chiliz,
    moonbeam,
    zkSync,
    polygonZkEvm,
  ],
  ssr: true,
});
