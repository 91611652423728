'use client';

import {
  TonConnectUIProvider,
  useIsConnectionRestored,
  useTonConnectUI,
  useTonWallet,
} from '@tonconnect/ui-react';
import React, { useEffect, useRef } from 'react';
import { useTranslations } from 'next-intl';

import type { ButtonProps } from '@zealy/design-system';
import { Button } from '@zealy/design-system';
import { useAuthenticatedUser } from '@zealy/queries';

import { authenticationAdapter } from './authenticationAdapter';

const MANIFEST_URL =
  'https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/tonconnect-manifest.json';

const payloadTTLMS = 1000 * 60 * 20;

export const ConnectButton = ({ buttonProps }: { buttonProps?: ButtonProps }) => {
  const t = useTranslations('settings.linked-accounts');
  const interval = useRef<ReturnType<typeof setInterval> | undefined>();
  const { refetch } = useAuthenticatedUser();
  const isConnectionRestored = useIsConnectionRestored();

  const methods = authenticationAdapter(refetch);

  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();
  useEffect(() => {
    clearInterval(interval.current);

    if (!wallet) {
      const refreshPayload = async () => {
        tonConnectUI.setConnectRequestParameters({ state: 'loading' });

        const value = await methods.getTonPayload();

        if (!value) {
          tonConnectUI.setConnectRequestParameters(null);
        } else {
          tonConnectUI.setConnectRequestParameters({
            state: 'ready',
            value: {
              tonProof: value,
            },
          });
        }
      };

      refreshPayload();
      setInterval(refreshPayload, payloadTTLMS);
      return;
    }

    if (wallet.connectItems?.tonProof && !('error' in wallet.connectItems.tonProof)) {
      methods
        .verifyTonProof({
          address: wallet.account.address,
          network: wallet.account.chain,
          public_key: wallet.account.publicKey!,
          proof: {
            timestamp: wallet.connectItems.tonProof.proof.timestamp,
            domain: wallet.connectItems.tonProof.proof.domain,
            payload: wallet.connectItems.tonProof.proof.payload,
            signature: wallet.connectItems.tonProof.proof.signature,
            state_init: wallet.account.walletStateInit,
          },
        })
        .then(result => {
          if (!result) {
            tonConnectUI.disconnect();
          }
        });
    } else {
      tonConnectUI.disconnect();
    }
  }, [wallet, isConnectionRestored]);

  const onClick = async () => {
    if (!tonConnectUI.connected) {
      await tonConnectUI.openModal();
    }
  };

  return (
    <Button {...buttonProps} onClick={onClick} variant={'muted'} color={'default'}>
      {t('connect-wallet')}
    </Button>
  );
};

// https://docs.moralis.io/authentication-api/solana/how-to-sign-in-with-solana-wallet-provider
export const ConnectTon = ({ buttonProps }: { buttonProps?: ButtonProps }) => {
  return (
    <TonConnectUIProvider manifestUrl={MANIFEST_URL}>
      <ConnectButton buttonProps={buttonProps} />
    </TonConnectUIProvider>
  );
};
