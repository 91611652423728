import { useTranslations } from 'next-intl';

import { TextField } from '@zealy/design-system';
import { TaskType } from '@zealy/utils';

import { PreviewTitle } from './PreviewTitle';

export const TextPreview = ({ type, index }: { type: TaskType; index: number }) => {
  const t = useTranslations('quest.type');

  return (
    <PreviewTitle index={index}>
      <TextField size="lg" name="value" placeholder={t(`${type as 'text'}.placeholder`)} />
    </PreviewTitle>
  );
};
