import React from 'react';
import { useFormContext } from 'react-hook-form';

import type { TweetTask as TaskType } from '@zealy/utils';

import { TweetTask } from '#components/Tasks/components/TweetClaim';

export const TweetPreview = ({ index }: { index: number }) => {
  const { watch } = useFormContext<{ tasks: TaskType[] }>();

  const task = watch(`tasks.${index}`);

  return <TweetTask task={task} />;
};
