import React from 'react';
import { useController } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import { Button, TaskCard } from '@zealy/design-system';
import { TwitterFilled } from '@zealy/icons';

import { TwitterUserInfo } from '#components/Tweet/TwitterUserInfo';

import type { QuestTask } from '../Tasks.types';
import { withQuest } from '../Tasks.funcs';

type WrapperProps = Extract<QuestTask, { type: 'twitterFollow' }>;

export const TwitterFollow = ({ task, error }: { task: WrapperProps; error?: string }) => {
  const t = useTranslations('ds.social.twitter.follow');

  return (
    <TaskCard type="twitter" title={t('header')}>
      <div className="flex flex-col gap-300 px-200 py-400">
        <TwitterUserInfo
          username={task.settings.username}
          name={task.metadata?.name}
          imageUrl={task.metadata?.imageUrl}
          size="lg"
        />
        <Button
          leftIcon={<TwitterFilled />}
          className="text-white w-full bg-task-type-twitter hover:bg-task-type-twitter-hover active:bg-task-type-twitter-press disabled:pointer-events-none"
          color="cta"
          variant="filled"
          size="lg"
          onClick={() => {
            window.open(
              `https://twitter.com/intent/follow?screen_name=${task.settings?.username}`,
              '_blank',
            );
          }}
          type="button"
        >
          {t('follow')}
        </Button>
        {error && <p className="body-component-md text-error-primary">{String(error)}</p>}
      </div>
    </TaskCard>
  );
};

export const TwitterFollowClaim = withQuest<unknown, 'twitterFollow'>(({ task }) => {
  const t = useTranslations('ds.social.twitter.follow');
  const { formState } = useController({
    name: task.id,
    defaultValue: {
      taskId: task.id,
      type: 'twitterFollow' as const,
    },
  });

  const error = formState.errors?.[task.id]?.message ?? '';

  return <TwitterFollow task={task} error={String(error)} />;
});
