'use client';

import type { DropdownMenuContentProps } from '@radix-ui/react-dropdown-menu';
import React, { useState } from 'react';
import { useTranslations } from 'next-intl';

import {
  DropdownMenuAnatomy as Anatomy,
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  DropdownContent,
  DropdownMenu,
  DropdownPortal,
} from '@zealy/design-system';
import { PlusLine } from '@zealy/icons';

import { useIsMobile } from '#hooks/useIsMobile';
import { cn } from '#utils/utils';

export interface PropertyDropdownProps extends DropdownMenuContentProps {
  name: string;
  /**
   * The trigger element.
   */
  children: React.ReactElement;
  options?: { value: string; icon?: React.ReactElement; label: string }[];
  // eslint-disable-next-line no-unused-vars
  onValueChange?: (value: string) => void;
  disabled?: boolean;
  allowCustom?: boolean;
  contentClassName?: string;
  searchPlaceholder?: string;
}

export const Combobox = ({
  name,
  children,
  options,
  onValueChange,
  disabled,
  asChild = true,
  className,
  allowCustom,
  contentClassName,
  searchPlaceholder,
  ...props
}: PropertyDropdownProps) => {
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(false);
  const t = useTranslations('common');
  const [search, setSearch] = useState('');

  const handleSelect = (value: string) => {
    setOpen(false);
    onValueChange?.(value);
  };

  return (
    <DropdownMenu name={name} size="sm" open={open} onOpenChange={setOpen} className={className}>
      <Anatomy.Trigger asChild={asChild} disabled={disabled} className="outline-none">
        {children}
      </Anatomy.Trigger>
      <DropdownPortal>
        <DropdownContent
          className={cn('min-w-[210px] max-h-[400px] overflow-y-auto !p-0', contentClassName)}
          side={isMobile ? 'bottom' : 'left'}
          align="start"
          {...props}
        >
          <Command loop>
            <CommandInput
              placeholder={searchPlaceholder ?? t('search')}
              autoFocus
              className="min-h-text-field-sm"
              value={search}
              onValueChange={setSearch}
            />

            <CommandList>
              <CommandEmpty>{t('no-results')}</CommandEmpty>

              <CommandGroup>
                {options?.map(({ value, icon, label }) => (
                  <CommandItem
                    value={label}
                    key={value}
                    onSelect={() => handleSelect(value)}
                    icon={icon}
                  >
                    {label}
                  </CommandItem>
                ))}
              </CommandGroup>
              {allowCustom && search && (
                <Button
                  size="sm"
                  variant="ghost"
                  className="w-full mt-100 border-t rounded-none"
                  key="custom"
                  onClick={() => handleSelect(search)}
                  leftIcon={<PlusLine />}
                >
                  {t('add-option', {
                    option: search,
                  })}
                </Button>
              )}
            </CommandList>
          </Command>
        </DropdownContent>
      </DropdownPortal>
    </DropdownMenu>
  );
};
