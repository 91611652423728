import React from 'react';
import { useTranslations } from 'next-intl';
import { useParams } from 'next/navigation';

import { Button, FormControl } from '@zealy/design-system';
import { useCommunity } from '@zealy/queries';

import { useEventTracker } from '#context';

export const Domino = () => {
  const t = useTranslations('quest.type.onChain.fields.domino');
  const { analytics } = useEventTracker();
  const { questId } = useParams<{ questId: string }>();
  const { data: community } = useCommunity();

  return (
    <FormControl name="domino" label={t('label')}>
      <div className="flex gap-100">
        <Button
          variant="muted"
          onClick={() => {
            analytics.track('Clicked Configure Domino', {
              type: 'logs',
              questId,
              communityId: community?.id,
            });
            window.open('https://domino.run/explore/templates/99', '_blank', 'noopener noreferrer');
          }}
        >
          {t('type.logs')}
        </Button>
        <Button
          variant="muted"
          onClick={() => {
            analytics.track('Clicked Configure Domino', {
              type: 'state',
              questId,
              communityId: community?.id,
            });
            window.open('https://domino.run/explore/templates/98', '_blank', 'noopener noreferrer');
          }}
        >
          {t('type.state')}
        </Button>
      </div>
    </FormControl>
  );
};
