'use client';

import React from 'react';

import { Stack } from '#components/Stack';

import { TaskBadge } from '../TaskBadge';
import { BadgesProps } from './TaskBadges.types';

export const TaskBadges = ({ limit, badges = [], ...rest }: BadgesProps) => {
  return (
    <Stack limit={limit} size={rest.size}>
      {badges.map((type, key) => (
        <TaskBadge key={key} {...{ ...rest, type, className: '-mr-75' }} />
      ))}
    </Stack>
  );
};
