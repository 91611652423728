import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslations } from 'next-intl';
import { cn } from '../../utils/cn';
import { Avatar } from '../Avatar';
import { TaskCard } from '../TaskCard';
import { twitterSpaceStyles } from './SocialTaskCards.styles';
import { InfoIcon, SetSpaceReminderIcon, SoundWaveIcon } from './SocialTasks.icons';
/**
 * The TwitterSpaceCard component is used to render a card that displays a Twitter Space
 */
export const TwitterSpaceCard = ({ className, metadata, reminderSet, onClickSetReminder, onClickListenLive, onClickPlayRecording, children, ...props }) => {
    function handleClickSetReminder() {
        onClickSetReminder?.();
    }
    function handleClickJoinSpace() {
        onClickListenLive?.();
    }
    function handleClickPlayRecording() {
        onClickPlayRecording?.();
    }
    const status = metadata?.status || 'scheduled';
    const t = useTranslations();
    return (_jsx(TaskCard, { type: "twitter", title: t('ds.social.twitter.space.header'), children: _jsxs("div", { className: "flex flex-col gap-300 px-200 py-400", children: [_jsx("div", { className: twitterSpaceStyles.card.wrapper(), children: _jsxs("article", { role: "article", className: twitterSpaceStyles.card.content(), children: [_jsxs("div", { className: "flex justify-start items-center space-x-50", children: [_jsx(Avatar, { size: "xs", className: "border border-white", src: metadata?.creator.imageUrl }), _jsx("a", { href: `https://twitter.com/${metadata?.creator.username}`, className: "font-bold flex gap-50 items-center hover:underline outline-none", children: _jsx("span", { children: metadata?.creator.name }) }), _jsx("div", { className: twitterSpaceStyles.card.hostBadge(), children: t('ds.social.twitter.space.host') })] }), _jsx("div", { className: cn(twitterSpaceStyles.card.title()), children: metadata?.title }), status === 'live' && (_jsxs("button", { className: twitterSpaceStyles.card.button({
                                    variant: 'default',
                                }), type: "button", onClick: handleClickJoinSpace, children: [_jsx(SoundWaveIcon, { className: "w-300" }), t('ds.social.twitter.space.button.listen')] })), status === 'scheduled' && !reminderSet && (_jsxs("button", { className: twitterSpaceStyles.card.button({
                                    variant: 'default',
                                }), type: "button", onClick: handleClickSetReminder, children: [_jsx(SetSpaceReminderIcon, { className: "w-300" }), t('ds.social.twitter.space.button.set-reminder')] })), status === 'scheduled' && reminderSet && (_jsxs("button", { className: twitterSpaceStyles.card.button({
                                    variant: 'secondary',
                                }), type: "button", onClick: handleClickSetReminder, children: [_jsx(SetSpaceReminderIcon, { className: "w-300" }), t('ds.social.twitter.space.button.reminder-set')] })), status === 'ended' && (_jsxs("button", { className: twitterSpaceStyles.card.button({
                                    variant: 'default',
                                }), type: "button", onClick: handleClickPlayRecording, children: [_jsx(SoundWaveIcon, { className: "w-300" }), t('ds.social.twitter.space.button.recording')] }))] }) }), _jsxs("div", { className: twitterSpaceStyles.alert.container(), role: "alert", children: [_jsx("div", { className: twitterSpaceStyles.alert.icon(), children: _jsx(InfoIcon, { className: "h-full" }) }), _jsx("div", { className: twitterSpaceStyles.alert.content(), children: _jsx("p", { children: t('ds.social.twitter.space.alert.description') }) })] }), children] }) }));
};
