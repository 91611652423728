import React from 'react';
import { useFormContext } from 'react-hook-form';

import { PostQuestInput } from '@zealy/utils';

import { cn } from '#utils/utils';

const taskPreview = cn(
  'border-task-card-preview border-task-card py-task-card-preview-y px-task-card-preview-x rounded-task-card-preview',
  'flex flex-col gap-task-card-container @container',
);

interface PreviewProps {
  /**
   * The index of the task in the array
   */
  index: number;
  /**
   * Input specific to type
   */
  children?: React.ReactNode;
}

export const PreviewTitle = ({ index, children }: PreviewProps) => {
  const { register } = useFormContext<PostQuestInput>();

  return (
    <div className={taskPreview}>
      <input
        className="bg-transparent w-full text-primary placeholder:text-primary task-card-preview-title outline-none placeholder:italic"
        placeholder="Type your instructions or a question here"
        {...register(`tasks.${index}.settings.title`)}
      />
      <textarea
        className="bg-transparent w-full text-secondary placeholder:text-placeholder task-card-preview-description outline-none"
        placeholder="Description (optional)"
        rows={2}
        {...register(`tasks.${index}.settings.description`)}
      />

      {children}
    </div>
  );
};
