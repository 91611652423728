import { useCallback, useEffect, useState } from 'react';

import { createContext } from '#context/createContext';

type ErrorMessage = string;

interface QuestError {
  errors: {
    [key: string]: ErrorMessage;
  };
  setError: (key: string, value: ErrorMessage) => void;
  clearError: (key: string) => void;
  isValid: boolean;
}

export const [ContextProvider, useErrorContext] = createContext<QuestError>({
  name: 'ErrorContext',
  strict: true,
  errorMessage: 'useErrorContext must be used within an <ErrorContext />',
});

/**
 * Used for errors that are set asynchroniously and that we don't want to remove when editing
 */
export const ErrorContext = ({
  initialValue,
  children,
}: {
  initialValue?: QuestError['errors'];
  children: React.ReactNode;
}) => {
  const [errors, setErrors] = useState<QuestError['errors']>(initialValue ?? {});

  const setError = useCallback(
    (key: string, value: ErrorMessage) => setErrors(prev => ({ ...prev, [key]: value })),
    [],
  );

  const clearError = useCallback(
    (key: string) =>
      setErrors(prev => {
        const { [key]: _, ...rest } = prev;
        return rest;
      }),
    [],
  );

  return (
    <ContextProvider
      value={{
        errors,
        setError,
        clearError,
        isValid: Object.keys(errors).length === 0,
      }}
    >
      {children}
    </ContextProvider>
  );
};

export const useError = (key: string, initialError?: ErrorMessage) => {
  const { errors, setError, clearError } = useErrorContext();

  useEffect(() => {
    if (initialError) setError(key, initialError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    error: errors[key],
    setError: (value: ErrorMessage) => setError(key, value),
    clearError: () => clearError(key),
  };
};
