export const TASK_COLORS = {
  nft: 'bg-task-type-on-chain',
  token: 'bg-task-type-on-chain',
  onChain: 'bg-task-type-on-chain',
  file: 'bg-task-type-file-upload',
  image: 'bg-task-type-file-upload',
  discord: 'bg-task-type-discord',
  telegram: 'bg-task-type-telegram',
  twitter: 'bg-task-type-twitter',
  tweet: 'bg-task-type-twitter',
  tweetReact: 'bg-task-type-twitter',
  twitterSpace: 'bg-task-type-twitter',
  twitterFollow: 'bg-task-type-twitter',
  partnership: 'bg-task-type-partnership',
  poll: 'bg-task-type-poll',
  quiz: 'bg-task-type-quiz',
  opinion: 'bg-task-type-opinion-scale',
  text: 'bg-task-type-text',
  date: 'bg-task-type-date',
  number: 'bg-task-type-number',
  url: 'bg-task-type-url',
  visitLink: 'bg-task-type-visit-link',
  invites: 'bg-task-type-invites',
  api: 'bg-task-type-api',
  tiktok: 'bg-task-type-tiktok',
} as const;
