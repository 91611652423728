'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import * as Base from '@radix-ui/react-tabs';
import { forwardRef } from 'react';
import { cx } from 'class-variance-authority';
import { TabsContextProvider } from './Tabs.context';
import { tabsStyles } from './Tabs.styles';
const Root = forwardRef(({ className, ...props }, ref) => (
// Adding the `group` so we can access the orientation
_jsx(Base.Root, { className: cx('group', className), ...props, ref: ref })));
const List = forwardRef(({ className, size, ...props }, ref) => {
    const context = { size };
    return (_jsx(TabsContextProvider, { value: context, children: _jsx(Base.List, { className: cx(tabsStyles({ size }), className), ref: ref, "data-scope": "Tabs", "data-part": "root", ...props }) }));
});
const Content = ({ className, ...props }) => (_jsx(Base.Content, { className: cx('outline-none', className), ...props }));
export const TabsAnatomy = {
    Root,
    List,
    Content,
};
