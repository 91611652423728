import { PopoverTrigger } from '@radix-ui/react-popover';
import { useLocalStorageValue } from '@react-hookz/web';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslations } from 'next-intl';
import { z } from 'zod';

import { Button, Popover, PopoverContent, TextField } from '@zealy/design-system';
import { GearFilled } from '@zealy/icons';
import { useCommunity } from '@zealy/queries';

import { useEventTracker } from '#context';

import type { APITask } from './APITests.const';
import { API_TEST_BODY } from './APITests.utils';

export const testUserSchema = z.object({
  discord: z
    .object({
      id: z.string().optional(),
      handle: z.string().optional(),
    })
    .optional(),
  twitter: z
    .object({
      username: z.string().optional(),
      id: z.string().optional(),
    })
    .optional(),
  email: z.string().email().optional(),
  wallet: z.string().optional(),
  'eth-mainnet': z.string().optional(),
  'zealy-connect': z.string().optional(),
});

export type TestUserData = z.infer<typeof testUserSchema>;

export const ConfigureTestUser = ({
  fields,
}: {
  fields: APITask['settings']['identifications'];
}) => {
  const t = useTranslations('quest.type.api');
  const [open, setOpen] = useState(false);
  const {
    value: testUser,
    set: setTestUser,
    remove,
  } = useLocalStorageValue<TestUserData>('testUser');
  const { analytics } = useEventTracker();
  const { data: community } = useCommunity();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<TestUserData>({
    resolver: zodResolver(testUserSchema),
    defaultValues: {
      discord: {
        id: testUser?.discord?.id ?? API_TEST_BODY.accounts.discord.id ?? '',
        handle: testUser?.discord?.handle ?? API_TEST_BODY.accounts.discord.handle ?? '',
      },
      twitter: {
        username: testUser?.twitter?.username ?? API_TEST_BODY.accounts.twitter.username ?? '',
        id: testUser?.twitter?.id ?? API_TEST_BODY.accounts.twitter.id ?? '',
      },
      email: testUser?.email ?? API_TEST_BODY.accounts.email ?? '',
      'eth-mainnet': testUser?.wallet ?? API_TEST_BODY.accounts['eth-mainnet'] ?? '',
      'zealy-connect': testUser?.['zealy-connect'] ?? API_TEST_BODY.accounts['zealy-connect'] ?? '',
    },
  });

  const onSubmit = (data: TestUserData) => {
    analytics.track('Configure API test user', { data, communityId: community?.id });
    setTestUser(data);
    setOpen(false);
  };

  const onReset = () => {
    remove();
    reset(API_TEST_BODY.accounts);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button leftIcon={<GearFilled />} size="sm" variant="ghost" mutedText className="self-end">
          {t('tests.configuration.title')}
        </Button>
      </PopoverTrigger>
      <PopoverContent variant="secondary" className="w-[436px] max-w-screen py-300 px-250">
        <form className="flex flex-col gap-300 w-full" onSubmit={handleSubmit(onSubmit)}>
          <p className="body-paragraph-lg-bold text-primary">Configure test data</p>
          {fields.includes('discord') && (
            <div className="flex gap-100">
              <TextField
                label={`${t(`fields.identifications.discord`)} ${t('tests.configuration.id')}`}
                placeholder={API_TEST_BODY.accounts.discord.id}
                {...register('discord.id')}
              />
              <TextField
                label={`${t(`fields.identifications.discord`)} ${t('tests.configuration.handle')}`}
                placeholder={API_TEST_BODY.accounts.discord.handle}
                {...register('discord.handle')}
              />
            </div>
          )}
          {fields.includes('twitter') && (
            <div className="flex gap-100">
              <TextField
                label={`${t(`fields.identifications.twitter`)} ${t('tests.configuration.id')}`}
                placeholder={API_TEST_BODY.accounts.twitter.id}
                {...register('twitter.id')}
              />
              <TextField
                label={`${t(`fields.identifications.twitter`)} ${t(
                  'tests.configuration.username',
                )}`}
                placeholder={API_TEST_BODY.accounts.twitter.username}
                {...register('twitter.username')}
              />
            </div>
          )}
          {fields.includes('email') && (
            <TextField
              label={t('fields.identifications.email')}
              placeholder={API_TEST_BODY.accounts.email}
              autoComplete="email"
              {...register('email')}
            />
          )}
          {fields.includes('wallet') && (
            <TextField
              label={t('fields.identifications.wallet')}
              placeholder={API_TEST_BODY.accounts.wallet}
              {...register('eth-mainnet')}
            />
          )}
          {fields.includes('zealy-connect') && (
            <TextField
              label={t('fields.identifications.zealyConnect')}
              placeholder={API_TEST_BODY.accounts.email}
              {...register('zealy-connect')}
            />
          )}
          <div className="flex justify-end items-center mt-300 gap-100">
            <Button variant="ghost" mutedText size="md" type="button" onClick={onReset}>
              {t('tests.configuration.reset')}
            </Button>
            <Button size="md">{t('tests.configuration.save')}</Button>
          </div>
        </form>
      </PopoverContent>
    </Popover>
  );
};
