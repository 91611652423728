import React from 'react';
import { useTranslations } from 'next-intl';
import NextLink from 'next/link';

import { Link } from '@zealy/design-system';

export const LinkField = ({
  name,
  namespace,
  link,
}: {
  name: string;
  namespace: string;
  link?: string;
}) => {
  const t = useTranslations('quest.type');

  // @ts-ignore
  const label = t(`${namespace}.label`);
  // @ts-ignore
  const description = t.rich(`${namespace}.description`, {
    link: chunk =>
      link ? (
        <Link underlined as={NextLink} href={link} size="sm">
          {chunk}
        </Link>
      ) : (
        chunk
      ),
  });

  return (
    <div className="flex gap-300">
      <div className="flex flex-col gap-25 flex-1">
        <p className="input-label-md text-task-card-primary">{label}</p>
        <p className="input-hint-md text-task-card-secondary">{description}</p>
      </div>
    </div>
  );
};
