import { useController, useFormContext } from 'react-hook-form';
import { useCopyToClipboard } from 'react-use';
import { useTranslations } from 'next-intl';

import { Alert, Badge, Button, TaskCard, TextField } from '@zealy/design-system';
import { CheckLine, CopyLine } from '@zealy/icons';

import { TASK_CONFIG } from '#constants/quests/Task.constants';

import type { QuestTask } from '../Tasks.types';
import { withQuest } from '../Tasks.funcs';

type WrapperProps = Extract<QuestTask, { type: 'tiktok' }>;

const type = 'tiktok' as const;
type Task = typeof type;

const TikTok = withQuest<WrapperProps, Task>(({ task }) => {
  const tC = useTranslations('common');
  const t = useTranslations('quest.type.tiktok');
  const { control, register } = useFormContext();
  const [state, copy] = useCopyToClipboard();

  const { formState, fieldState } = useController<{
    [key: string]: {
      taskId: string;
      value: '';
      type: 'tiktok';
    };
  }>({
    name: task.id,
    control,
    defaultValue: {
      taskId: task.id,
      value: '',
      type,
    },
  });

  const error = fieldState.isTouched
    ? formState.errors?.[task.id]?.value?.message ?? formState.errors?.[task.id]?.message
    : undefined;

  const handleCopy = () => {
    copy(task.settings.descriptionWords.join(' '));
  };

  return (
    <TaskCard type="tiktok" title={t('label')} icon={TASK_CONFIG.tiktok.icon}>
      <div className="p-300 flex flex-col gap-200">
        <TextField
          label={t('fields.videoUrl.label')}
          placeholder={t('fields.videoUrl.placeholder')}
          isInvalid={!!error}
          hint={error ? String(error) : undefined}
          {...register(`${task.id}.value`)}
        />

        {task.settings.minViewCount > 0 && (
          <Alert title={`${t('fields.minViewCount.label')}: ${task.settings.minViewCount}`} />
        )}

        {task.settings.descriptionWords.length > 0 && (
          <Alert
            title={t('fields.descriptionWords.label')}
            description={
              <div className="flex gap-100 min-w-0 flex-wrap overflow-hidden">
                {task.settings.descriptionWords.map(word => (
                  <Badge key={word} className="min-w-0 justify-start overflow-hidden max-w-full">
                    {word}
                  </Badge>
                ))}
              </div>
            }
            actions={
              <Button
                size="sm"
                variant="muted"
                leftIcon={state.value ? <CheckLine /> : <CopyLine />}
                onClick={handleCopy}
              >
                {state.value ? tC('copied') : tC('copy')}
              </Button>
            }
          />
        )}
      </div>
    </TaskCard>
  );
});

export default TikTok;
