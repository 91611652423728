import { generateNonce, verifySignedMessageForNetwork } from '@zealy/queries';

import type { AuthenticationAdapter } from '@rainbow-me/rainbowkit/dist/components/RainbowKitProvider/AuthenticationContext';
import { SiweMessage } from 'siwe';
import { chainIdToNetwork } from '@zealy/utils';
import { createAuthenticationAdapter } from '@rainbow-me/rainbowkit';
import { toast } from '#components/Toaster';

// https://www.rainbowkit.com/docs/authentication
export const authenticationAdapter = (
  desiredChainId: number,
  onSuccess?: () => void | Promise<void>,
) =>
  createAuthenticationAdapter<SiweMessage>({
    getNonce: generateNonce,
    createMessage: ({ nonce, address, chainId }) => {
      return new SiweMessage({
        domain: window.location.host,
        address,
        statement: 'Sign in to Zealy',
        uri: window.location.origin,
        version: '1',
        chainId: desiredChainId,
        nonce,
      });
    },
    getMessageBody: ({ message }) => {
      return message.prepareMessage();
    },
    verify: async ({ message, signature }) => {
      try {
        await verifySignedMessageForNetwork(signature, message, chainIdToNetwork(message.chainId));
        await onSuccess?.();
        return true;
      } catch (e) {
        if (e instanceof Error) {
          toast.error(e.message);
        }
        console.error('[AuthenticationAdapter:Verify] failed', e);
        return false;
      }
    },
    signOut: async () => {},
  });
