import type { ChangeEvent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import type { TextSubmissionProps } from '@zealy/design-system';
import { TextAreaSubmission, TextSubmission } from '@zealy/design-system';

import type { ClaimTaskInput } from '../Tasks.types';
import { withQuest } from '../Tasks.funcs';

type WrapperProps = Omit<TextSubmissionProps, 'name' | 'title' | 'description' | 'taskName'>;

const TextSubmissionWrapper = withQuest<WrapperProps, 'text' | 'number' | 'url'>(({ task }) => {
  const t = useTranslations('quest');
  const { control, formState } =
    useFormContext<Record<string, ClaimTaskInput<'text' | 'number' | 'url'>>>();

  const { field, fieldState } = useController({
    control,
    name: task.id,
    defaultValue: {
      taskId: task.id,
      value: '',
      type: task.type,
    } as ClaimTaskInput<'text' | 'number' | 'url'>,
  });

  const onChange = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    field.onChange({
      ...field.value,
      value: task.type === 'number' ? Number(value) : task.type === 'url' ? value.trim() : value,
    });
  };

  const Component = task.type === 'text' ? TextAreaSubmission : TextSubmission;

  return (
    <Component
      variant={task.type}
      taskName={t(`type.${task.type}.label`)}
      placeholder={t(`type.${task.type}.placeholder`)}
      title={task.settings.title}
      description={task.settings.description}
      onChange={onChange}
      name={field.name}
      isInvalid={fieldState.isDirty && fieldState.invalid}
      hint={
        fieldState.isDirty
          ? formState.errors?.[task.id]?.message ?? formState.errors?.[task.id]?.value?.message
          : ''
      }
    />
  );
});

export default TextSubmissionWrapper;
