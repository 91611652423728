import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import { Checkbox, formControlStyles } from '@zealy/design-system';

import { cn } from '#utils/utils';

import { access } from './TextField';

export const CheckboxField = ({
  name,
  namespace,
  options,
  className,
}: {
  name: string;
  className?: string;
  namespace: string;
  options: string[];
}) => {
  // @ts-ignore
  const t = useTranslations('quest.type');

  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  // @ts-ignore
  const label = t(`${namespace}.label`);
  // @ts-ignore
  const description = t(`${namespace}.description`);

  const fields: string[] = watch(name) || [];
  const enabledFields: { [option: string]: boolean } = options.reduce(
    (acc, option) => ({
      [option]: fields.includes(option),
      ...acc,
    }),
    {},
  );

  const error = access(`${name}.message`, errors);

  const onCheckedChange = (option: string) => (checked: boolean) => {
    if (checked && !enabledFields[option]) {
      return setValue(name, [...fields, option], {
        shouldValidate: true,
        shouldDirty: true,
      });
    } else if (!checked && enabledFields[option]) {
      return setValue(
        name,
        fields.filter(o => o !== option),
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
    }
  };

  return (
    <div className={cn('flex flex-col gap-100')}>
      {label && (
        <div className="flex flex-col gap-25 flex-1">
          {label && <p className="input-label-md text-task-card-primary">{label}</p>}
          {description && <p className="input-hint-md text-task-card-secondary">{description}</p>}
        </div>
      )}
      <div className={cn('flex gap-50 flex-wrap', className)}>
        {options.map(option => (
          <Checkbox
            key={option}
            checked={enabledFields[option]}
            onCheckedChange={onCheckedChange(option)}
          >
            {/* @ts-ignore */}
            {t(`${namespace}.${option}`)}
          </Checkbox>
        ))}
      </div>
      {error && (
        <p
          className={formControlStyles.hint({
            state: 'error',
            size: 'md',
          })}
        >
          {error}
        </p>
      )}
    </div>
  );
};
