import { API } from './API';
import { CheckboxField } from './CheckboxField';
import { CommandField } from './CommandField';
import { KeywordField } from './KeywordField';
import { LinkField } from './LinkField';
import { RangeField } from './RangeField';
import { SelectField } from './SelectField';
import { SwitchField } from './SwitchField';
import { TextField } from './TextField';
import { TypeField } from './TypeField';

export const FIELDS = {
  switch: SwitchField,
  type: TypeField,
  text: TextField,
  select: SelectField,
  checkbox: CheckboxField,
  keywords: KeywordField,
  range: RangeField,
  link: LinkField,
  command: CommandField,
} as const;
