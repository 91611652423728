import { useWatch } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import { TextField } from '@zealy/design-system';

export const TikTokPreview = ({ index }: { index: number }) => {
  const t = useTranslations('quest.type.tiktok');
  const settings = useWatch({
    name: `tasks.${index}.settings`,
  });

  return (
    <div className="border-task-card-preview border-task-card py-task-card-preview-y px-task-card-preview-x rounded-task-card-preview flex flex-col gap-task-card-container @container">
      <TextField
        name="videoUrl"
        label={t('fields.videoUrl.label')}
        placeholder={t('fields.videoUrl.placeholder')}
      />
    </div>
  );
};
