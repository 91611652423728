import React, { useState } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import { Button, Tag, TextField } from '@zealy/design-system';
import { ArrowCornerCwLbLine } from '@zealy/icons';

export const KeywordField = ({
  name,
  namespace,
  ...props
}: {
  name: string;
  namespace: string;
  textarea?: boolean;
}) => {
  const [value, setValue] = useState('');
  const t = useTranslations('quest.type');

  const { fields, append, remove } = useFieldArray({
    name,
  });

  const values = useWatch({
    name,
  });

  // @ts-ignore
  const label = t(`${namespace}.label`);
  // @ts-ignore
  const placeholder = t(`${namespace}.placeholder`);

  const onEnter = () => {
    append(value);
    setValue('');
  };

  return (
    <div className="flex flex-col gap-200 min-w-0">
      <div className="relative">
        <TextField
          name={name}
          label={label}
          placeholder={placeholder}
          {...props}
          value={value}
          onChange={e => setValue(e.currentTarget.value)}
          onKeyUpCapture={e => {
            if (e.key === 'Enter') {
              onEnter();
            }
          }}
          className="pr-50"
          rightIcon={
            <Button
              size="sm"
              variant="muted"
              leftIcon={<ArrowCornerCwLbLine />}
              isDisabled={!value}
              onClick={onEnter}
            >
              Enter
            </Button>
          }
        />
      </div>
      <div className="flex flex-wrap gap-100 min-w-0">
        {fields.map((field, index) => (
          <Tag key={field.id} onRemove={() => remove(index)} size="sm" wrapText>
            {/* Hacky solution because fields is expecting an object */}
            <p className="min-w-0 truncate max-w-[300px]">{values?.[index]}</p>
          </Tag>
        ))}
      </div>
    </div>
  );
};
