import React from 'react';
import { ConnectButton as Base } from '@rainbow-me/rainbowkit';
import { useTranslations } from 'next-intl';

import type { ButtonProps } from '@zealy/design-system';
import type { BlockchainNetwork } from '@zealy/utils';
import { Button } from '@zealy/design-system';

import { WalletProvider } from './Provider';

export const ConnectButton = ({
  blockchain = 'eth-mainnet',
  buttonProps,
}: {
  blockchain?: BlockchainNetwork;
  buttonProps?: ButtonProps;
}) => {
  const t = useTranslations('settings.linked-accounts');

  return (
    <WalletProvider blockchain={blockchain}>
      <Base.Custom>
        {({ account, chain, openChainModal, openConnectModal, authenticationStatus, mounted }) => {
          const ready = mounted && authenticationStatus !== 'loading';

          const connected =
            ready &&
            account &&
            chain &&
            (!authenticationStatus || authenticationStatus === 'authenticated');

          return (
            <div
              {...(!ready && {
                'aria-hidden': true,
                style: {
                  opacity: 0,
                  pointerEvents: 'none',
                  userSelect: 'none',
                },
              })}
            >
              {(() => {
                if (chain?.unsupported) {
                  return (
                    <Button
                      onClick={openChainModal}
                      type="button"
                      color="cta"
                      variant="muted"
                      {...buttonProps}
                    >
                      Wrong network
                    </Button>
                  );
                }

                return (
                  <Button
                    onClick={openConnectModal}
                    type="button"
                    color="cta"
                    variant="muted"
                    {...buttonProps}
                  >
                    {buttonProps?.children ?? connected ? t('update') : t('connect-wallet')}
                  </Button>
                );
              })()}
            </div>
          );
        }}
      </Base.Custom>
    </WalletProvider>
  );
};
