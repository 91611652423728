import { useController, useFormContext } from 'react-hook-form';

import type { DiscordJoinServerCardProps } from '@zealy/design-system';
import { DiscordJoinServerCard } from '@zealy/design-system';

import type { ClaimTaskInput } from '../Tasks.types';
import { withQuest } from '../Tasks.funcs';

const type = 'discord' as const;
type Task = typeof type;

const Discord = withQuest<DiscordJoinServerCardProps, Task>(({ task }) => {
  const { control, formState } = useFormContext<Record<string, ClaimTaskInput<Task>>>();

  useController({
    name: task.id,
    control,
    defaultValue: {
      taskId: task.id,
      type,
    },
  });

  return (
    <DiscordJoinServerCard
      metadata={task.metadata}
      inviteLink={task.settings.inviteUrl}
      className="w-full"
      error={formState.errors?.[task.id]?.message}
    />
  );
});

export default Discord;
