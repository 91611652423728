import { PostQuestInput } from '@zealy/utils';

import { envConfig } from '#app/config';

export const TESTS = [
  'response-time',
  'always-success',
  'response-status',
  'auth-error',
  'response-message',
] as const;

export type Reason = (typeof TESTS)[number];

export type APITask = Extract<PostQuestInput['tasks'][number], { type: 'api' }>;

export type TestResult = {
  success: boolean;
  reason?: Reason | 'unknown-error';
  data?: {
    status?: number;
    data: any;
  };
};

export const TEN_SECONDS = envConfig.env === 'test' ? 1000 : 10000;
