'use client';

import { useMemo } from 'react';

import type { CommunityMember } from '@zealy/queries';
import type { GetQuestOutput } from '@zealy/utils';
import { useAuthenticatedUser, useCommunity, useZealyConnectionStatus } from '@zealy/queries';

import { getMissingAuth } from './Tasks.utils';

const isZealyConnectTask = (task: GetQuestOutput['tasks'][0]) =>
  task.type === 'api' && task.settings.identifications.includes('zealy-connect');

export const useMissingAuth = (
  tasks: GetQuestOutput['tasks'],
  rewards: GetQuestOutput['rewards'],
  showRequired = true,
) => {
  const { data: community } = useCommunity();

  const tasksWithoutZealyConnect = tasks.filter(t => !isZealyConnectTask(t));

  const requiredFields = showRequired ? community?.requiredFields : undefined;
  const user = useAuthenticatedUser<CommunityMember>();

  return useMemo(() => {
    if (!user.data || !community) return [];

    return getMissingAuth({
      requiredFields,
      rewards,
      tasks: tasksWithoutZealyConnect,
      user: user.data,
      communityBlockchain: community?.blockchain,
    });
  }, [community, requiredFields, tasksWithoutZealyConnect, user]);
};

const useZealyConnectStatusForQuest = (questId: string, includesZealyConnect: boolean) => {
  const status = useZealyConnectionStatus({ questId, enabled: includesZealyConnect });
  return {
    missing: status.data?.tasks?.filter(({ connected }) => !connected) ?? [],
    isConnected: (taskId: string) =>
      status.data?.tasks.some(({ id, connected }) => id === taskId && connected),
  };
};

export const useZealyConnectStatusForQuestAndTask = (
  questId: string,
  task: GetQuestOutput['tasks'][0],
) => {
  const includesZealyConnect = useMemo(() => isZealyConnectTask(task), [task]);
  return useZealyConnectStatusForQuest(questId, includesZealyConnect);
};

export const useZealyConnectStatusForQuestAndTasks = (
  questId: string,
  tasks: GetQuestOutput['tasks'],
) => {
  const includesZealyConnect = useMemo(() => tasks.some(isZealyConnectTask), [tasks]);
  return useZealyConnectStatusForQuest(questId, includesZealyConnect);
};
