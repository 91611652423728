import type { ReactElement } from 'react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import type { TaskType } from '@zealy/utils';
import { FormControl, Tag } from '@zealy/design-system';

import { TASK_CONFIG } from '#constants/quests/Task.constants';

export interface TypeOption {
  value: TaskType;
  icon?: ReactElement;
}

export interface TypeFieldProps {
  name: string;
  options: TypeOption[];
}

export const TypeField = ({ name, options }: TypeFieldProps) => {
  const t = useTranslations('quest.type');

  const { watch, setValue, trigger } = useFormContext();

  const value = watch(name);

  const handleSelect = async (type: string) => {
    const lastDotIndex = name.lastIndexOf('.');
    const taskPath = name.substring(0, lastDotIndex);

    const taskDefaultConfig = TASK_CONFIG[type as keyof typeof TASK_CONFIG]?.defaultValue;
    if (taskDefaultConfig) {
      setValue(taskPath, taskDefaultConfig, {
        shouldValidate: true,
        shouldDirty: true,
      });
    } else {
      setValue(name, type, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }

    await trigger();
  };

  return (
    <FormControl name="type" label={t('related')}>
      <div className="flex gap-100 items-center overflow-x-auto no-scrollbar">
        {options.map(option => (
          <Tag
            key={option.value}
            selected={value === option.value}
            onClick={() => handleSelect(option.value)}
            leftIcon={option.icon}
          >
            {t(`${option.value}.label`)}
          </Tag>
        ))}
      </div>
    </FormControl>
  );
};
