'use client';
import { createContext } from '../../utils/component.context';
export const [TabsContextProvider, useTabsContext] = createContext({
    name: 'TabsContext',
    strict: true,
    hookName: 'useTabsContext',
    providerName: 'TabsProvider',
    defaultValue: {
        size: 'md',
    },
});
