import { useFormContext } from 'react-hook-form';

import { FileTask } from '@zealy/utils';

import { FileUpload } from '#components/FileUpload';

export const FilePreview = ({ index }: { index: number }) => {
  const { watch } = useFormContext<{ tasks: FileTask[] }>();

  const settings = watch(`tasks.${index}.settings`);

  return (
    <FileUpload
      {...{
        sizeLimit: settings?.maxFileSize,
        limit: settings?.maxFileCount,
      }}
    />
  );
};
