import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import { TextField, TwitterSpaceCard } from '@zealy/design-system';
import { useTwitterSpace } from '@zealy/queries';
import { TwitterSpaceTask } from '@zealy/utils';

import { useDebouncedValue } from '#hooks/useDebouncedValue';

export const TwitterSpacePreview = ({ index }: { index: number }) => {
  const t = useTranslations('quest.type.twitterSpace.fields.password');

  const { formState } = useFormContext<{ tasks: TwitterSpaceTask[] }>();

  const task = useWatch({ name: `tasks.${index}` });

  const fieldIsTouched = formState.touchedFields?.['tasks']?.[index]?.['settings']?.['spaceUrl'];
  const fieldIsValid =
    !formState.errors?.['tasks']?.[index]?.['settings']?.['spaceUrl']?.['message'];

  const debouncedValue = useDebouncedValue(fieldIsValid ? task.settings.spaceUrl : '', 500);

  const space = useTwitterSpace(debouncedValue, {
    enabled: !!debouncedValue && (!!fieldIsTouched || !task?.metadata),
  });

  return (
    <TwitterSpaceCard
      spaceUrl={task?.settings?.spaceUrl || ''}
      metadata={{
        ...task['metadata'],
        title: space.data?.title ?? task['metadata']?.title,
        spaceId: space.data?.id ?? task['metadata']?.spaceId,
        status: space.data?.state ?? task['metadata']?.status,
        creator: {
          imageUrl: space.data?.users?.[0].profile_image_url ?? task['metadata']?.creator.imageUrl,
          name: space.data?.users?.[0].name ?? task['metadata']?.creator.name,
          username: space.data?.users?.[0].username ?? task['metadata']?.creator.username,
        },
      }}
    >
      {task.settings?.password && (
        <TextField
          {...{
            name: 'password',
            size: 'lg',
            label: t('label'),
            placeholder: t('placeholder'),
            hint: t('description'),
          }}
        />
      )}
    </TwitterSpaceCard>
  );
};
