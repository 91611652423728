'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { Command as CommandPrimitive } from 'cmdk';
import { SearchLine } from '@zealy/icons';
import { cn } from '../../utils/cn';
import { Dialog, DialogContent } from '../Dialog';
import { Kbd } from '../Kbd';
import { Skeleton } from '../Skeleton';
const Command = React.forwardRef(({ className, ...props }, ref) => (_jsx(CommandPrimitive, { ref: ref, className: cn('flex h-full w-full flex-col overflow-hidden rounded-command-modal bg-tertiary text-command-item', className), ...props })));
Command.displayName = CommandPrimitive.displayName;
const CommandDialog = ({ children, ...props }) => {
    return (_jsx(Dialog, { ...props, children: _jsx(DialogContent, { hideClose: true, className: "overflow-hidden p-0 shadow-lg bg-command-modal", children: _jsx(Command, { loop: true, className: "md:w-6400 [&_[cmdk-group-heading]]:px-command-group-title [&_[cmdk-group-heading]]:command-group-title [&_[cmdk-group-heading]]:text-command-group-title [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:py-command-group [&_[cmdk-input-wrapper]_svg]:h-command-icon-input [&_[cmdk-input-wrapper]_svg]:w-command-icon-input [&_[cmdk-input]]:h-command-item [&_[cmdk-item]]:px-command-item [&_[cmdk-item]]:h-command-item [&_[cmdk-item]_svg]:h-command-icon-item [&_[cmdk-item]_svg]:w-command-icon-item", children: children }) }) }));
};
const CommandInput = React.forwardRef(({ className, children, ...props }, ref) => (_jsxs("div", { className: "flex items-center border-b-command px-text-field-md-x relative", "cmdk-input-wrapper": "", children: [_jsx(SearchLine, { className: "h-icon-md w-icon-h-icon-md shrink-0 icon-secondary" }), _jsx(CommandPrimitive.Input, { ref: ref, className: cn('flex h-text-field-md w-full rounded-md px-command-input bg-transparent text-field-text-md placeholder:text-field-text-md outline-none placeholder:text-text-field-placeholder disabled:cursor-not-allowed disabled:opacity-50', className), ...props }), children] })));
CommandInput.displayName = CommandPrimitive.Input.displayName;
const CommandList = React.forwardRef(({ className, ...props }, ref) => (_jsx(CommandPrimitive.List, { ref: ref, className: cn('overflow-y-auto overflow-x-hidden p-command-list', className), ...props })));
CommandList.displayName = CommandPrimitive.List.displayName;
const CommandEmpty = React.forwardRef((props, ref) => (_jsx(CommandPrimitive.Empty, { ref: ref, className: "p-command-item text-center text-command-item-disabled command-group-title", ...props })));
CommandEmpty.displayName = CommandPrimitive.Empty.displayName;
const CommandGroup = React.forwardRef(({ className, ...props }, ref) => (_jsx(CommandPrimitive.Group, { ref: ref, className: cn('overflow-hidden py-command-group text-command-group-title [&_[cmdk-group-heading]]:px-command-group-title [&_[cmdk-group-heading]]:flex [&_[cmdk-group-heading]]:items-center [&_[cmdk-group-heading]]:h-command-group-title [&_[cmdk-group-heading]]:command-group-title', className), ...props })));
CommandGroup.displayName = CommandPrimitive.Group.displayName;
const CommandSeparator = React.forwardRef(({ className, ...props }, ref) => (_jsx(CommandPrimitive.Separator, { ref: ref, className: cn('-mx-1 h-12 bg-[var(--color-border-command)]', className), ...props })));
CommandSeparator.displayName = CommandPrimitive.Separator.displayName;
const CommandShortcut = ({ className, children, ...props }) => {
    const firstChild = children?.toString();
    const shortcuts = firstChild?.split('');
    if (!shortcuts)
        return null;
    return (_jsx("span", { className: cn('ml-auto inline-flex gap-tooltip-keyboard-shortcuts-x', className), ...props, children: shortcuts.map(shortcut => (_jsx(Kbd, { children: shortcut }, shortcut))) }));
};
CommandShortcut.displayName = 'CommandShortcut';
const CommandItem = React.forwardRef(({ className, icon, shortcut, children, ...props }, ref) => (_jsxs(CommandPrimitive.Item, { ref: ref, className: cn('relative flex text-command-item cursor-default select-none gap-command-item items-center rounded-sm px-command-item h-command-item command-item outline-none', 'aria-selected:bg-command-item-selected data-[disabled]:pointer-events-none data-[disabled]:text-command-item-disabled', className), ...props, children: [icon &&
            React.cloneElement(icon, {
                className: cn('icon-command-primary h-command-icon-item w-command-icon-item', icon.props.className),
            }), _jsx("span", { className: "line-clamp-1 min-w-0", children: children }), shortcut && _jsx(CommandShortcut, { children: shortcut })] })));
CommandItem.displayName = CommandPrimitive.Item.displayName;
const CommandLoading = React.forwardRef((props, ref) => (_jsxs(CommandPrimitive.Loading, { ref: ref, ...props, children: [_jsx(Skeleton, { className: "h-button-md rounded-button-sm my-50" }, "0"), _jsx(Skeleton, { className: "h-button-md rounded-button-sm my-50" }, "1"), _jsx(Skeleton, { className: "h-button-md rounded-button-sm my-50" }, "2"), _jsx(Skeleton, { className: "h-button-md rounded-button-sm my-50" }, "3"), _jsx(Skeleton, { className: "h-button-md rounded-button-sm mt-50" }, "4")] })));
CommandLoading.displayName = CommandPrimitive.Loading.displayName;
export { Command, CommandDialog, CommandInput, CommandList, CommandEmpty, CommandGroup, CommandItem, CommandShortcut, CommandSeparator, CommandLoading, };
