import React, { FocusEventHandler } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import { TextField as Input, TextArea } from '@zealy/design-system';

import { useError } from '../../../../FormError.context';

// allows any object
export const access = (path: string, object: any): string | undefined => {
  return path.split('.').reduce((o, i) => o?.[i], object);
};

export const TextField = ({
  name,
  namespace,
  setOnBlur,
  type,
  textarea,
  trim,
  ...props
}: {
  name: string;
  namespace: string;
  setOnBlur?: boolean;
  trim?: boolean;
  type: 'textarea' | 'text' | 'number' | undefined;
  textarea?: boolean;
}) => {
  const Component = textarea ? TextArea : Input;
  const t = useTranslations('quest.type');
  const {
    setValue,
    watch,
    register,
    formState: { errors },
  } = useFormContext();

  const context = useError(name);

  // @ts-ignore
  const label = t(`${namespace}.label`);
  // @ts-ignore
  const placeholder = t(`${namespace}.placeholder`);
  // @ts-ignore
  const hint = t(`${namespace}.hint`);

  const error = access(`${name}.message`, errors) ?? context.error;

  const fieldProps = setOnBlur
    ? {
        // We're setting the value onBlur in order to not trigger metadata fetching on every keystroke https://github.com/orgs/react-hook-form/discussions/3620
        onBlur: ((e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
          setValue(name, trim ? e.target.value?.trim() : e.target.value, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }) as FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>,
        name,
      }
    : register(name, type === 'number' ? { valueAsNumber: true } : {});

  return (
    <Component
      label={label}
      placeholder={placeholder}
      {...props}
      {...(type === 'number' ? { type: 'number' } : {})}
      isInvalid={!!error}
      hint={error ?? hint}
      defaultValue={watch(name)}
      {...fieldProps}
    />
  );
};
