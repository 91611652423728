import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslations } from 'next-intl';
import { EmailFilled } from '@zealy/icons';
import { cn } from '../../utils/cn';
import { Button } from '../Button';
import { formControlStyles } from '../FormControl/FormControl.styles';
import { ProgressBar } from '../ProgressBar';
import { TaskCard } from '../TaskCard';
import { LinkCopyGroup } from './LinkCopyGroup';
export const InviteTask = ({ className, value = 0, max, inviteLink, onShowInvites, error, baseURL, steps, ...props }) => {
    const invitesRemaining = Math.max(max - value, 0);
    const t = useTranslations('quest.type.invites');
    const tx = useTranslations('invites-modal');
    return (_jsxs(TaskCard, { type: "invites", className: cn('py-400 px-200 flex flex-col gap-300', className), ...props, children: [_jsx(ProgressBar, { value: value, max: max, description: invitesRemaining ? t('missing-invites', { count: invitesRemaining }) : '', size: "lg", variant: "brand" }), _jsx(LinkCopyGroup, { link: inviteLink }), steps && steps.length > 0 && (_jsxs("section", { className: "bg-tertiary border border-tertiary rounded-3xl p-300", children: [_jsx("h2", { className: "body-component-lg-bold pb-200", children: tx('howto.title') }), _jsx("ol", { className: "flex flex-col gap-150", children: steps?.map((item, index) => (_jsxs("li", { className: "flex flex-row gap-100", children: [_jsxs("div", { className: "body-component-md-bold", children: [index + 1, "."] }), _jsxs("div", { children: [_jsx("h3", { className: "body-component-md-bold", children: item.title }), _jsx("p", { className: "body-paragraph-md text-secondary", children: item.description })] })] }, index))) })] })), onShowInvites && (_jsx(Button, { type: "button", size: "xl", variant: "muted", leftIcon: _jsx(EmailFilled, {}), onClick: onShowInvites, children: t('show-invites') })), error && _jsx("p", { className: formControlStyles.hint({ state: 'error' }), children: error })] }));
};
