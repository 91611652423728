'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Trigger } from '@radix-ui/react-tabs';
import { Children, cloneElement, forwardRef } from 'react';
import { cx } from 'class-variance-authority';
import { useTabsContext } from './Tabs.context';
import { tabItemStyles } from './Tabs.styles';
const Root = forwardRef(({ className, size: propSize, as, selected, ...props }, ref) => {
    const size = propSize ?? useTabsContext().size;
    const Component = as ?? Trigger;
    return (_jsx(Component, { ref: ref, "data-scope": "TabItem", "data-part": "Root", className: cx(tabItemStyles.root({ size }), className), ...(selected ? { 'data-state': 'active' } : {}), ...props }));
});
const Label = forwardRef(({ children, className, size: propSize, ...props }, ref) => {
    const size = propSize ?? useTabsContext().size;
    return (_jsx("span", { ref: ref, "data-scope": "TabItem", "data-part": "label", className: cx(tabItemStyles.label({ size }), className), ...props, children: children }));
});
const Icon = forwardRef(({ children, className, color, size: propSize, ...props }, ref) => {
    const size = propSize ?? useTabsContext().size;
    const icon = Children.only(children);
    return cloneElement(icon, {
        ref,
        className: cx(tabItemStyles.icon({ size, color }), icon.props.className, className),
        'data-scope': 'TabItem',
        'data-part': 'icon',
        ...props,
    });
});
export const TabItem = forwardRef(({ children, size: propSize, leftIcon, label, ...props }, ref) => {
    const size = propSize ?? useTabsContext().size;
    return (_jsxs(Root, { ref: ref, size: size, ...props, children: [leftIcon && _jsx(Icon, { size: size, children: leftIcon }), label && _jsx(Label, { size: size, children: label }), children] }));
});
export const ItemAnatomy = {
    Root,
    Label,
    Icon,
};
