import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useTokenContractMetadata } from '@zealy/queries';
import { NATIVE_TOKEN_ADDRESS, TOKENS_BY_CHAIN } from '@zealy/utils';

import type { QuestTask } from '#components/Tasks/Tasks.types';
import { useError } from '#app/cw/[subdomain]/(app)/questboard/admin/[questId]/_components/FormError.context';
import { Token } from '#components/Tasks/components/Token';

export const TokenPreview = ({ index }: { index: number }) => {
  const { setValue } = useFormContext<{
    tasks: Extract<QuestTask, { type: 'token' }>[];
  }>();
  const task = useWatch({
    name: `tasks.${index}`,
  }) as Extract<QuestTask, { type: 'token' }>;

  const lowerCaseContract = task.settings.contract.toLowerCase();
  const metadataExisting = TOKENS_BY_CHAIN[task.settings.network].find(
    ({ contractAddress }) => contractAddress.toLowerCase() === lowerCaseContract,
  );

  const contractMetadata = useTokenContractMetadata(
    {
      contract: task.settings.contract,
      network: task.settings.network,
    },
    {
      enabled:
        !metadataExisting &&
        !!task.settings.contract &&
        !!task.settings.network &&
        task.settings.contract !== NATIVE_TOKEN_ADDRESS,
    },
  );

  const metadata = metadataExisting
    ? metadataExisting
    : contractMetadata.data
    ? contractMetadata.data
    : !contractMetadata.isError
    ? task.metadata
    : undefined;

  const metadataError = useError(task.id ?? task.settings.contract, undefined);

  useEffect(() => {
    if (typeof task.settings?.minBalance === 'number')
      // Convert from 1e-7 to 0.0000001
      setValue(
        `tasks.${index}.settings.minBalance`,
        task.settings.minBalance.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 18,
        }) as unknown as number,
      );
    if (metadata && metadata.name !== task.metadata?.name)
      setValue(`tasks.${index}.metadata`, metadata);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata, index]);

  useEffect(() => {
    if (contractMetadata.isError) {
      metadataError.setError(contractMetadata.error?.message);
    } else {
      metadataError.clearError();
    }
    return () => {
      metadataError.clearError();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractMetadata?.isError]);

  const error = metadataError.error;

  return <Token task={task} error={error} />;
};
