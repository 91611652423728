import React from 'react';

import { Badge, BadgeProps } from '@zealy/design-system';

import { cn } from '#utils/utils';

export const Stack = ({
  children,
  limit = 2,
  size = 'xxs',
}: {
  children: React.ReactElement | React.ReactElement[];
  limit?: number;
  size?: BadgeProps['size'];
}) => {
  if (!children) {
    return null;
  }
  const childArray = React.Children.toArray(children);
  const childrenToRender = childArray.slice(0, limit) as React.ReactElement[];
  const diff = childArray.length - limit;

  return (
    <div className="flex">
      {childrenToRender.map((child, index) =>
        React.cloneElement(child, {
          className: cn(child.props?.className, {
            '-ml-75': index !== 0,
          }),
        }),
      )}

      {diff > 0 && (
        <Badge className="-ml-75 backdrop-blur-sm px-25" size={size}>
          +{diff}
        </Badge>
      )}
    </div>
  );
};
