import React, { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import { TaskCard, TextField } from '@zealy/design-system';
import { getTweetIdFromUrl, isTweetUrlValid } from '@zealy/utils';

import { useError } from '#app/cw/[subdomain]/(app)/questboard/admin/[questId]/_components/FormError.context';
import { Tweet as TweetCard } from '#components/Tweet';

import type { ClaimTaskInput, QuestTask } from '../Tasks.types';
import { withQuest } from '../Tasks.funcs';

type WrapperProps = Extract<QuestTask, { type: 'tweetReact' }>;

export const TweetReactTask = ({ task, error }: { task: WrapperProps; error?: string }) => {
  const t = useTranslations();
  const { register } = useFormContext<Record<string, ClaimTaskInput<'tweetReact'>>>();

  const tweetId = getTweetIdFromUrl(task.settings.tweetUrl);

  return (
    <TaskCard type="twitter" title={t('ds.social.twitter.reactions.header')}>
      <div className="flex flex-col gap-300 px-200 py-400">
        <TweetCard
          input={{
            tweetId: tweetId,
          }}
          actions={task.settings.actions}
          tweetCreator={task.metadata?.creator}
          tweetText={task.metadata?.content}
          createdAt={task.metadata?.createdAt}
        />
        {task.settings.actions?.includes('reply') && (
          <TextField
            label={t('quest.type.tweetReact.fields.replyUrl.label')}
            placeholder={t('quest.type.tweetReact.fields.replyUrl.placeholder')}
            isInvalid={!!error}
            hint={error}
            {...register(`${task.id}.tweetUrl`)}
          />
        )}
      </div>
    </TaskCard>
  );
};

export const TweetReactClaim = withQuest<unknown, 'tweetReact'>(({ task }) => {
  const t = useTranslations();

  const { formState, watch } = useFormContext<Record<string, ClaimTaskInput<'tweetReact'>>>();

  const { fieldState } = useController({
    name: task.id,
    defaultValue: {
      taskId: task.id,
      reply: undefined,
      type: 'tweetReact' as const,
      tweetUrl: '',
    },
  });

  const { clearError, error, setError } = useError(
    `${task.id}.tweetUrl`,
    !task.settings.actions?.includes('reply') ? undefined : 'Required',
  );

  const tweetUrl = watch(`${task.id}.tweetUrl`);

  useEffect(() => {
    if (!task.settings.actions?.includes('reply')) return;

    const isValidTweetURL = !!tweetUrl && isTweetUrlValid(tweetUrl);
    if (isValidTweetURL) {
      clearError();
    } else if (!isValidTweetURL) {
      setError('Invalid tweet URL');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tweetUrl]);

  return (
    <TweetReactTask
      task={task}
      error={
        fieldState.isDirty
          ? error ??
            formState.errors?.[task.id]?.message ??
            formState.errors?.[task.id]?.tweetUrl?.message
          : t('quest.type.tweetReact.fields.replyUrl.hint')
      }
    />
  );
});
