import { ApiPreview } from './ApiPreview';
import { DiscordPreview } from './DiscordPreview';
import { FilePreview } from './FilePreview';
import { InvitesPreview } from './InvitesPreview';
import { LinkPreview } from './LinkPreview';
import { NFTPreview } from './NFTPreview';
import { OpinionPreview } from './OpinionPreview';
import { PartnershipPreview } from './PartnershipPreview';
import { PollPreview } from './PollPreview';
import { TelegramPreview } from './TelegramPreview';
import { TextPreview } from './TextPreview';
import { TikTokPreview } from './TikTokPreview';
import { TokenPreview } from './TokenPreview';
import { TweetPreview } from './TweetPreview';
import { TweetReactPreview } from './TweetReactPreview';
import { TwitterFollowPreview } from './TwitterFollowPreview';
import { TwitterSpacePreview } from './TwitterSpacePreview';

export const BASE_TASK_PREVIEW = {
  api: ApiPreview,
  onChain: ApiPreview,
  text: TextPreview,
  visitLink: LinkPreview,
  opinion: OpinionPreview,
  invites: InvitesPreview,
  discord: DiscordPreview,
  partnership: PartnershipPreview,
  telegram: TelegramPreview,
  poll: PollPreview,
  quiz: PollPreview,
  file: FilePreview,
  twitterSpace: TwitterSpacePreview,
  twitterFollow: TwitterFollowPreview,
  tweet: TweetPreview,
  tweetReact: TweetReactPreview,
  tiktok: TikTokPreview,
  nft: NFTPreview,
  token: TokenPreview,
} as const;
