import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import type { BlockchainNetwork } from '@zealy/utils';
import { Button, FormControl } from '@zealy/design-system';
import { ChevronDownLine } from '@zealy/icons';
import { useTokenContractMetadata } from '@zealy/queries';

import { useError } from '../../../../FormError.context';
import { Combobox } from '../../../../SidePanel/PropertiesPanel/Combobox';

// allows any object
export const access = (path: string, object: any): string | undefined => {
  return path.split('.').reduce((o, i) => o?.[i], object);
};

export const CommandField = ({
  name,
  namespace,
  options: getOptions,
  ...props
}: {
  name: string;
  namespace: string;
  options: ({ network }: { network: BlockchainNetwork }) => { value: string; label: string }[];
}) => {
  const t = useTranslations('quest.type');
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const context = useError(name);
  // @ts-ignore
  const label = t(`${namespace}.label`);
  // @ts-ignore
  const hint = t(`${namespace}.hint`);
  // @ts-ignore
  const placeholder = t(`${namespace}.placeholder`);

  const error = access(`${name}.message`, errors) ?? context.error;

  const value = watch(name);

  const network = watch(name.replace('contract', 'network'));

  const options = getOptions({ network });

  const labelValue = options.find(option => option.value === value)?.label;

  const metadata = useTokenContractMetadata(
    {
      contract: value,
      network,
    },
    {
      enabled: !!value && !labelValue && !!network,
    },
  );

  return (
    <FormControl name={name} label={label} hint={error ?? hint}>
      <Combobox
        side="bottom"
        id="blockchain"
        className="w-full"
        name="blockchain"
        onValueChange={val =>
          setValue(name, val, {
            shouldValidate: true,
            shouldDirty: true,
          })
        }
        options={options}
        allowCustom
        searchPlaceholder={placeholder}
        contentClassName="w-6400 max-w-screen"
      >
        <Button
          variant="ghost"
          className="border-select justify-start"
          rightIcon={<ChevronDownLine className="ml-auto" />}
        >
          {labelValue ?? metadata.data?.symbol}
        </Button>
      </Combobox>
    </FormControl>
  );
};
