import { useController, useFormContext } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import type { PollProps } from '@zealy/design-system';
import { Poll as TaskPoll } from '@zealy/design-system';

import type { ClaimTaskInput } from '../Tasks.types';
import { withQuest } from '../Tasks.funcs';

type WrapperProps = Omit<PollProps, 'name' | 'title' | 'description' | 'taskName' | 'options'>;

const type = 'poll' as const;
type Task = typeof type;

const Poll = withQuest<WrapperProps, Task>(({ task }) => {
  const t = useTranslations();

  const { control, formState } = useFormContext<Record<string, ClaimTaskInput<Task>>>();

  const { field } = useController({
    name: task.id,
    control,
    defaultValue: {
      taskId: task.id,
      values: [],
      type,
    },
  });

  const options = task.settings?.options.map(x => ({
    as: 'button',
    value: x.label,
    children: x.label,
    // @ts-ignore
    image: x.imageUrl,
  }));

  const onChange = (values: string | string[]) => {
    if (typeof values === 'string') {
      field.onChange({ ...field.value, values: [values] });
      return;
    }

    field.onChange({ ...field.value, values });
  };

  return (
    <TaskPoll
      taskName={t('quest.type.poll.label')}
      title={task.settings.title}
      description={task.settings.description ?? ''}
      onChange={onChange}
      options={options}
      multiple={task.settings.multipleSelection}
      other={task.settings.otherOption}
      error={formState.errors?.[task.id]?.message}
    />
  );
});

export default Poll;
