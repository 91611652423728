import { useWatch } from 'react-hook-form';

import { EmbedURL } from '@zealy/design-system';
import { useOpenGraph } from '@zealy/queries';

import { useDebouncedValue } from '#hooks/useDebouncedValue';

export const LinkPreview = ({ index }: { index: number }) => {
  const settings = useWatch({
    name: `tasks.${index}.settings`,
  });

  const urlDebounced = useDebouncedValue(settings?.linkUrl ?? '', 600);

  const { data, isLoading } = useOpenGraph(urlDebounced);

  return <EmbedURL url={settings?.linkUrl} {...data} loading={isLoading} />;
};
