import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import { Alert, Button, TaskCard, TextField } from '@zealy/design-system';

import type { ClaimTaskInput, QuestTask } from '../Tasks.types';
import { withQuest } from '../Tasks.funcs';

type WrapperProps = Extract<QuestTask, { type: 'tweet' }>;

export const TweetTask = ({ task, error }: { task: WrapperProps; error?: string }) => {
  const { register } = useFormContext<Record<string, ClaimTaskInput<'tweet'>>>();

  const t = useTranslations();

  const onSubmitTweet = () => {
    const text = task.settings.defaultTweet
      ? task.settings.defaultTweet
      : task.settings.tweetWords.join(' ');
    window.open(`https://twitter.com/intent/tweet?&text=${encodeURIComponent(text)}`, '_blank');
  };

  return (
    <TaskCard type="twitter" title={t('ds.social.twitter.tweet.header')}>
      <div className="flex flex-col gap-300 px-200 py-400 min-w-0">
        <Button
          variant="filled"
          color="cta"
          className={
            'text-white w-full bg-task-type-twitter hover:bg-task-type-twitter-hover active:bg-task-type-twitter-press'
          }
          size={'lg'}
          onClick={onSubmitTweet}
        >
          {t('ds.social.twitter.tweet.tweet')}
        </Button>

        {task.settings.tweetWords.length > 0 && (
          <Alert
            title={t('ds.social.twitter.tweet.alert.description')}
            description={task.settings.tweetWords.map((word, i) => (
              <p className="min-w-0 break-all" key={`${word}:${i}`}>
                {word}
              </p>
            ))}
          />
        )}
        <TextField
          label={t('quest.type.tweet.fields.tweetUrl.label')}
          placeholder={t('quest.type.tweet.fields.tweetUrl.placeholder')}
          isInvalid={!!error}
          hint={error ?? ''}
          {...register(`${task.id}.tweetUrl`)}
        />
      </div>
    </TaskCard>
  );
};

export const TweetClaim = withQuest<unknown, 'tweet'>(({ task }) => {
  const { formState } = useFormContext<Record<string, ClaimTaskInput<'tweet'>>>();

  const { fieldState } = useController({
    name: task.id,
    defaultValue: {
      taskId: task.id,
      type: 'tweet' as const,
      tweetUrl: '',
    },
  });

  return (
    <TweetTask
      task={task}
      error={
        fieldState.isDirty
          ? formState.errors?.[task.id]?.message ?? formState.errors?.[task.id]?.tweetUrl?.message
          : undefined
      }
    />
  );
});
