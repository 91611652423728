import { cva } from 'class-variance-authority';
export const tabsStyles = cva([
    'box-border flex group-data-[orientation=vertical]:flex-col',
    'group-data-[orientation=vertical]:min-w-[200px]',
    'group-data-[orientation=horizontal]:w-full group-data-[orientation=horizontal]:overflow-x-auto flex-shrink-0 group-data-[orientation=horizontal]:no-scrollbar',
], {
    variants: {
        size: {
            sm: [
                'group-data-[orientation=vertical]:p-tabs-list-y-sm group-data-[orientation=vertical]:gap-tabs-list-sm-y',
                'group-data-[orientation=horizontal]:p-tabs-list-x-sm group-data-[orientation=horizontal]:gap-tabs-list-sm-x',
            ],
            md: [
                'group-data-[orientation=vertical]:p-tabs-list-y-md group-data-[orientation=vertical]:gap-tabs-list-md-y',
                'group-data-[orientation=horizontal]:p-tabs-list-x-md group-data-[orientation=horizontal]:gap-tabs-list-md-x',
            ],
        },
    },
    defaultVariants: {
        size: 'md',
    },
});
export const root = cva([
    'box-border',
    'outline-none',
    'select-none',
    'group flex items-center justify-start',
    'focus-visible:ring-tabs-focus',
    'text-tabs-primary',
    'data-[state=active]:bg-tabs-selected hover:bg-tabs-hover active:bg-tabs-press',
    'disabled:bg-tabs disabled:cursor-not-allowed disabled:text-tabs-disabled',
    'transition-colors duration-200 ease-in-out',
    'flex-shrink-0',
    'group-data-[orientation=vertical]:w-full',
], {
    variants: {
        size: {
            sm: 'tabs-sm h-tabs-sm-y px-tabs-item-sm-x gap-tabs-item-sm-x rounded-tabs-sm ',
            md: 'tabs-md h-tabs-md-y px-tabs-item-md-x gap-tabs-item-md-x rounded-tabs-md ',
        },
    },
    defaultVariants: {
        size: 'md',
    },
});
export const label = cva([
    'select-none',
    'text-left',
    'flex-1',
    'text-tabs-primary',
    'group-disabled:text-tabs-disabled',
    'whitespace-nowrap',
], {
    variants: {
        size: {
            sm: 'tabs-sm ',
            md: 'tabs-md',
        },
    },
    defaultVariants: {
        size: 'md',
    },
});
export const icon = cva(['icon-tabs  group-disabled:icon-tabs-disabled', 'transition-colors duration-200 ease-in-out'], {
    variants: {
        size: {
            sm: 'h-tabs-icon-sm w-tabs-icon-sm',
            md: 'h-tabs-icon-md w-tabs-icon-md',
        },
        color: {
            neutral: '',
            branded: 'group-data-[state=active]:icon-tabs-selected',
        },
    },
    defaultVariants: {
        size: 'md',
        color: 'branded',
    },
});
export const tabItemStyles = {
    root,
    label,
    icon,
};
