import type { DisclaimerComponent } from '@rainbow-me/rainbowkit';
import type { RainbowKitProviderProps } from '@rainbow-me/rainbowkit/dist/components/RainbowKitProvider/RainbowKitProvider';
import { useEffect } from 'react';
import {
  darkTheme,
  RainbowKitAuthenticationProvider,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { useTranslations } from 'next-intl';
import { useChainId, useDisconnect, useSwitchChain, WagmiProvider } from 'wagmi';

import type { BlockchainNetwork } from '@zealy/utils';
import { useAuthenticatedUser } from '@zealy/queries';
import { BLOCKCHAINS } from '@zealy/utils';

import { toast } from '#components/Toaster';
import { PRIVACY_URL, TERMS_AND_CONDITIONS_URL } from '#constants';

import { authenticationAdapter } from './authenticationAdapter';
import { config } from './config';

const Disclaimer: DisclaimerComponent = ({ Text, Link }) => {
  const t = useTranslations('settings');
  return (
    <Text>
      {t.rich('disclaimer', {
        ToU: children => <Link href={TERMS_AND_CONDITIONS_URL}>{children}</Link>,
        Privacy: children => <Link href={PRIVACY_URL}>{children}</Link>,
      })}
    </Text>
  );
};

const InternalRainbowProvider = ({
  children,
  props,
  blockchain = 'eth-mainnet',
}: {
  children: React.ReactNode;
  props?: RainbowKitProviderProps;
  blockchain?: BlockchainNetwork;
}) => {
  const user = useAuthenticatedUser();
  const { disconnect } = useDisconnect();
  const chainId = useChainId();
  const { switchChain } = useSwitchChain();

  const desiredChainId = BLOCKCHAINS[blockchain].chainId;

  useEffect(() => {
    try {
      if (chainId !== desiredChainId && switchChain) {
        switchChain({ chainId: desiredChainId });
      }
    } catch (e) {
      toast.error('Failed to switch network');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, desiredChainId]);

  return (
    <RainbowKitAuthenticationProvider
      adapter={authenticationAdapter(desiredChainId, async () => {
        await user.refetch();

        // Disconnect Wagmi so that it doesn't keep a reference to the current account
        // and allows to reopen the connect modal instead of the current account one
        return disconnect();
      })}
      status={'unauthenticated'}
    >
      <RainbowKitProvider
        theme={darkTheme({
          accentColor: 'var(--color-text-brand-primary)',
          fontStack: 'system',
        })}
        appInfo={{
          disclaimer: Disclaimer,
        }}
        initialChain={BLOCKCHAINS[blockchain].chainId}
        {...props}
      >
        {children}
      </RainbowKitProvider>
    </RainbowKitAuthenticationProvider>
  );
};

export const WalletProvider = ({
  children,
  props,
  blockchain = 'eth-mainnet',
}: {
  children: React.ReactNode;
  props?: RainbowKitProviderProps;
  blockchain?: BlockchainNetwork;
}) => {
  return (
    <WagmiProvider config={config} reconnectOnMount={false}>
      <InternalRainbowProvider blockchain={blockchain} props={props}>
        {children}
      </InternalRainbowProvider>
    </WagmiProvider>
  );
};
