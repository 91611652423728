import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import type { TweetReactTask as TaskType } from '@zealy/utils';
import { useTweet } from '@zealy/queries';

import { TweetReactTask } from '#components/Tasks/components/TweetReact';
import { useDebouncedValue } from '#hooks/useDebouncedValue';

import { useError } from '../../../../FormError.context';

export const TweetReactPreview = ({ index }: { index: number }) => {
  const t = useTranslations('quest.type.tweetReact.errors');

  const { formState, watch } = useFormContext<{ tasks: TaskType[] }>();

  const task = watch(`tasks.${index}`);
  const { setError, clearError, error } = useError(`tasks.${index}.settings.tweetUrl`);

  const fieldIsTouched = !!formState.touchedFields?.['tasks']?.[index]?.['settings']?.['tweetUrl'];
  const fieldIsValid =
    !formState.errors?.['tasks']?.[index]?.['settings']?.['tweetUrl']?.['message'];

  const tweetUrl = task.settings.tweetUrl;
  const tweetId = /\/status\/(\d+)/.exec(tweetUrl)?.[1] ?? '';

  const debouncedValue = useDebouncedValue(fieldIsValid ? tweetId : '', 500);

  const tweet = useTweet(debouncedValue, {
    enabled: !!debouncedValue && (fieldIsTouched || !task?.metadata),
  });

  useEffect(() => {
    if (!tweet.isFetched) return;

    if (tweet.data?.created_at) clearError();
    else setError(t('not-found'));
    return clearError;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tweet.isFetched, tweet.data?.created_at]);

  const tweetSettings =
    !fieldIsTouched && task?.metadata
      ? task?.metadata
      : {
          content: tweet.data?.text ?? '',
          tweetId: tweet.data?.id_str ?? '',
          creator: tweet.data?.user,
          createdAt: tweet.data?.created_at,
        };

  return (
    <TweetReactTask
      task={{
        ...task,
        metadata: tweetSettings,
      }}
      error={error ?? formState.errors?.['tasks']?.[index]?.['settings']?.['tweetUrl']?.['message']}
    />
  );
};
