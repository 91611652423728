'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Label } from '@radix-ui/react-label';
import { Root, Thumb } from '@radix-ui/react-switch';
import { forwardRef } from 'react';
import { cn } from '../../utils/cn';
import { styles } from './Switch.styles';
export const Switch = forwardRef(({ className, label, ...props }, ref) => {
    return (_jsxs("div", { className: "flex items-center gap-200", children: [_jsx(Root, { className: cn(styles.root, className), ref: ref, ...props, children: _jsx(Thumb, { className: styles.thumb, children: _jsx("span", { className: styles.thumbInner }) }) }), label && (_jsx(Label, { className: styles.label, htmlFor: props.id, "aria-disabled": props.disabled, children: label }))] }));
});
