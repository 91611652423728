import { useWatch } from 'react-hook-form';

import { NFT } from '#components/Tasks/components/NFT';

export const NFTPreview = ({ index }: { index: number }) => {
  const task = useWatch({
    name: `tasks.${index}`,
  });

  return <NFT task={task} />;
};
