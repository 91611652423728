import { cva } from 'class-variance-authority';
export const alertStyles = {
    container: cva([
        'flex flex-shrink-0 justify-start items-start gap-150 p-200 rounded-component-md text-sm bg-component-tertiary text-primary min-w-0 w-full',
    ], {
        variants: {
            state: {
                default: '',
                error: 'border border-error-secondary bg-error-secondary',
            },
        },
        defaultVariants: {
            state: 'default',
        },
    }),
    icon: cva(['w-200 h-200']),
    content: cva(['leading-[1.4] body-component-md flex flex-col gap-150 min-w-0']),
};
export const socialInteractionContainerStyles = cva(['box-border']);
export const twitterInteractionStyles = {
    root: socialInteractionContainerStyles,
    tweetcontainer: cva([
        'box-border border border-solid border-twitter-interaction-boundary rounded-component-sm overflow-hidden min-w-[250px] w-full max-w-sm',
        'flex flex-col flex-shrink-0 relative',
    ]),
    article: cva(['relative box-border outline-none text-sm', 'flex flex-col gap-150']),
    media: {
        box: cva(['overflow-hidden aspect-video rounded-component-sm']),
        image: cva(['w-full h-full object-cover']),
    },
    action: {
        group: cva(['flex gap-100 md:gap-600 text-sm items-center text-twitter-interaction-action']),
        intent: {
            cell: cva([
                'group rounded-full outline-none mt-150 flex justify-center items-center gap-50 pr-100',
                "data-[intent='reply']:focus-visible:bg-twitter-interaction-reply-hover",
                "data-[intent='retweet']:focus-visible:bg-twitter-interaction-retweet-hover",
                "data-[intent='like']:focus-visible:bg-twitter-interaction-like-hover",
            ]),
            iconwrapper: cva([
                'peer flex justify-center items-center w-400 h-400 rounded-full',
                'text-twitter-interaction-action',
                "group-data-[intent='reply']:group-hover:bg-twitter-interaction-reply-hover group-data-[intent='reply']:group-focus-visible:bg-twitter-interaction-reply-hover group-data-[intent='reply']:group-active:bg-twitter-interaction-reply-active",
                "group-data-[intent='retweet']:group-hover:bg-twitter-interaction-retweet-hover group-data-[intent='retweet']:group-focus-visible:bg-twitter-interaction-retweet-hover group-data-[intent='retweet']:group-active:bg-twitter-interaction-retweet-active",
                "group-data-[intent='like']:group-hover:bg-twitter-interaction-like-hover group-data-[intent='like']:group-focus-visible:bg-twitter-interaction-like-hover group-data-[intent='like']:group-active:bg-twitter-interaction-like-active",
            ], {
                variants: {
                    status: {
                        idle: 'text-twitter-interaction-action',
                        active: [
                            "group-data-[intent='reply']:text-twitter-interaction-reply-primary",
                            "group-data-[intent='retweet']:text-twitter-interaction-retweet-primary",
                            "group-data-[intent='like']:text-twitter-interaction-like-primary",
                        ],
                    },
                },
                defaultVariants: {
                    status: 'idle',
                },
            }),
            icon: cva([
                'flex justify-center items-center w-200 h-200',
                "group-data-[intent='reply']:group-hover:text-twitter-interaction-reply-primary group-data-[intent='reply']:group-focus-visible:text-twitter-interaction-reply-primary",
                "group-data-[intent='retweet']:group-hover:text-twitter-interaction-retweet-primary group-data-[intent='retweet']:group-focus-visible:text-twitter-interaction-retweet-primary",
                "group-data-[intent='like']:group-hover:text-twitter-interaction-like-primary group-data-[intent='like']:group-focus-visible:text-twitter-interaction-like-primary",
            ]),
            text: cva([
                'font-bold',
                "group-data-[intent='reply']:group-hover:text-twitter-interaction-reply-primary group-data-[intent='reply']:group-focus-visible:text-twitter-interaction-reply-primary",
                "group-data-[intent='retweet']:group-hover:text-twitter-interaction-retweet-primary group-data-[intent='retweet']:group-focus-visible:text-twitter-interaction-retweet-primary",
                "group-data-[intent='like']:group-hover:text-twitter-interaction-like-primary group-data-[intent='like']:group-focus-visible:text-twitter-interaction-like-primary",
            ], {
                variants: {
                    status: {
                        idle: '',
                        active: [
                            "group-data-[intent='reply']:text-twitter-interaction-reply-primary",
                            "group-data-[intent='retweet']:text-twitter-interaction-retweet-primary",
                            "group-data-[intent='like']:text-twitter-interaction-like-primary",
                        ],
                    },
                },
                defaultVariants: {
                    status: 'idle',
                },
            }),
        },
    },
    reply: {
        input: cva([
            'outline-none w-full focus:outline-none bg-transparent px-50 text-base placeholder:text-base placeholder:text-twitter-interaction-input-placeholder',
        ]),
    },
    alert: alertStyles,
};
export const twitterSpaceStyles = {
    card: {
        wrapper: cva(['flex flex-col gap-150 p-150 bg-twitter-space-card  rounded-component-sm']),
        content: cva(['relative flex flex-col gap-150 flex-shrink-0']),
        title: cva(['font-bold text-[22px] leading-[27px]']),
        hostBadge: cva([
            'flex items-center p-50 text-twitter-space-action bg-twitter-space-host-badge text-sm rounded-component-xxs leading-none',
        ]),
        button: cva([
            'outline-none focus-visible:ring-button-focus flex justify-center gap-100 items-center w-full h-500 rounded-full font-bold',
        ], {
            variants: {
                variant: {
                    default: 'bg-twitter-space-action text-twitter-space-action-primary',
                    secondary: 'bg-twitter-space-action-secondary text-twitter-space-action border-2 border-twitter-space-action-secondary-border',
                },
            },
            defaultVariants: {
                variant: 'default',
            },
        }),
    },
    alert: alertStyles,
};
export const socialActionStyles = {
    container: cva(['flex flex-col justify-start items-center gap-300']),
    avatar: {
        wrapper: cva(['w-[72px] h-[72px] rounded-full overflow-hidden']),
        image: cva(['object-cover w-full h-full bg-component-secondary']),
    },
    name: cva(['body-paragraph-xl-bold text-primary flex gap-50 items-center hover:underline']),
    description: cva(['body-paragraph-md text-secondary text-center']),
};
