import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import { FormControl, Input } from '@zealy/design-system';

const access = (path: string, object: any): string | undefined => {
  return path.split('.').reduce((o, i) => o?.[i], object);
};

export const RangeField = ({
  name,
  namespace,
  optional,
}: {
  name: string;
  namespace: string;
  subfields?: string[];
  optional?: boolean;
}) => {
  const t = useTranslations('quest.type');
  const {
    register,
    formState: { errors },
  } = useFormContext();

  // @ts-ignore
  const label = t(`${namespace}.label`);

  const errorStart = access(`${name}.start.message`, errors);
  const errorEnd = access(`${name}.end.message`, errors);
  const error = errorStart ?? errorEnd;

  return (
    <FormControl
      name={name}
      label={label}
      optional={optional}
      state={error ? 'error' : undefined}
      hint={error}
    >
      <div className="flex gap-200 items-center">
        <Input
          // @ts-ignore
          placeholder={t(`${namespace}.placeholder.start`)}
          isInvalid={!!errorStart}
          {...register(`${name}.start`)}
        />
        <p className="body-component-md-bold text-primary">{t('opinion.to')}</p>
        <Input
          isInvalid={!!errorEnd}
          // @ts-ignore
          placeholder={t(`${namespace}.placeholder.end`)}
          {...register(`${name}.end`)}
        />
      </div>
    </FormControl>
  );
};
