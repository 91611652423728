'use client';

import React from 'react';

import { BadgeAnatomy as Anatomy } from '@zealy/design-system';
import { QuestionFilled } from '@zealy/icons';

import { TASK_CONFIG } from '#constants/quests/Task.constants';
import { cn } from '#utils/utils';

import type { TaskBadgeProps } from './TaskBadge.types';

export const TaskBadge = ({ type, size = 'sm', className, ...rest }: TaskBadgeProps) => {
  const { icon: Icon = QuestionFilled, color } = TASK_CONFIG[type] ?? {};

  return (
    <Anatomy.Root
      {...{ size }}
      {...rest}
      variant={rest.disabled ? 'disabled' : undefined}
      data-disabled={rest.disabled}
      className={cn([
        'group/task-badge shadow-xs !px-0',
        'group-data-[disabled=true]/quest-card:bg-badge data-[disabled=true]:bg-badge',
        'group-data-[claimed=pending]/quest-card:bg-badge',
        className,
        color,
      ])}
    >
      <Anatomy.Icon
        className={cn([
          'group-data-[disabled=true]/task-badge:icon-badge-neutral-disabled',
          'group-data-[disabled=true]/quest-card:icon-badge-neutral-disabled',
          'group-data-[claimed=pending]/quest-card:icon-badge-neutral-disabled',
          'icon-onbrand-primary',
        ])}
      >
        <Icon />
      </Anatomy.Icon>
    </Anatomy.Root>
  );
};
