import { useController } from 'react-hook-form';

import type { TelegramJoinGroupCardProps } from '@zealy/design-system';
import { TelegramJoinGroupCard } from '@zealy/design-system';

import { withQuest } from '../Tasks.funcs';

const type = 'telegram' as const;
type Task = typeof type;

const Telegram = withQuest<TelegramJoinGroupCardProps, Task>(({ task }) => {
  useController({
    name: task.id,
    defaultValue: {
      taskId: task.id,
      type,
    },
  });

  return (
    <TelegramJoinGroupCard
      metadata={task.metadata}
      inviteLink={task.settings.inviteUrl}
      className="w-full"
    />
  );
});

export default Telegram;
